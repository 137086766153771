import { mergeDeepRight } from 'ramda';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState, Result, Criteria } from './types';

export const initialState: ContainerState = {
  criteria: undefined,
  results: undefined,
  filters: undefined,
};

const profileSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    search(state, action: PayloadAction<Criteria>) {
      state.criteria = action.payload;
    },
    setSearchResults(state, action: PayloadAction<Result[]>) {
      state.results = action.payload;
    },
    updateFilters(state, action) {
      state.filters = mergeDeepRight(state.filters, action.payload);
    },
    resetFilters(state, action) {
      let newFilters;
      if (action.payload) {
        newFilters = mergeDeepRight({}, state.filters);
        action.payload.forEach(filterName => delete newFilters[filterName]);
      } else {
        newFilters = undefined;
      }
      state.filters = newFilters;
    },
  },
});

export const { actions, reducer, name: sliceKey } = profileSlice;
