import React from 'react';

export default function Zenu() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="12" height="12" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 12C18.6274 12 24 6.62742 24 0H12V12Z"
      />
      <rect
        x="24"
        y="24"
        width="12"
        height="12"
        transform="rotate(-180 24 24)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 12C5.37258 12 1.53306e-06 17.3726 9.53674e-07 24L12 24L12 12Z"
      />
    </svg>
  );
}
