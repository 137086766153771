import { createGlobalStyle } from 'styled-components';
import { ThemeType } from '../theme';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    background-color: ${props => (props.theme as ThemeType).colors.background};
  }

  label,
  body {
    font-family: 'Inter', sans-serif;
  }

  #root {
    min-height: 100%;
    min-width: 100%;
  }

  p,
  span,
  label,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Inter', sans-serif;
  }

  input, select {
    font-family: inherit;
    font-size: inherit;
  }

  hr {
    background-color: ${props => (props.theme as ThemeType).colors.background};
  }
`;
