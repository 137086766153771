import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.system || initialState;

export const selectProgress2 = createSelector(
  [selectDomain],
  systemState => systemState.progress,
);

export const selectProgress = state => state.system.progress;

export const selectSubtitle = createSelector(
  [selectDomain],
  systemState => systemState.subtitle,
);

export const selectColor = createSelector(
  [selectDomain],
  systemState => systemState.color,
);

export const selectIsFullPage = createSelector(
  [selectDomain],
  systemState => systemState.isFullPage,
);
