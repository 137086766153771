import React from 'react';

import styled from 'styled-components';

import { Avatar as AntAvatar } from 'antd';
import CameraIcon from '@ant-design/icons/CameraOutlined';

import IconButton from 'app/components/common/IconButton';

import UploadImage from './UploadImage';

import useAction from 'utils/useAction';

import { actions as loginActions } from 'app/containers/Login/slice';
import { actions as profileActions } from 'app/containers/Profile/slice';

type AvatarProps = {
  src: string;
  isEditMode: Boolean;
};

export default function Avatar({ src, isEditMode }: AvatarProps) {
  const [isEditAvatar, setIsEditAvatar] = React.useState(false);

  const setUser = useAction(loginActions.setUser);
  const setProfile = useAction(profileActions.setProfile);

  const handleChangePicture = avatar => {
    setUser({ avatar });
    setProfile({ avatar, profile: {} });
  };
  const handleOpenEdit = () => setIsEditAvatar(true);
  const handleCloseEdit = () => setIsEditAvatar(false);

  return (
    <AvatarRoot>
      <AntAvatar shape="circle" size={120} src={src} />
      {isEditMode && (
        <EditAvatarRoot>
          <StyledIconButton
            size="medium"
            variant="contained"
            onClick={handleOpenEdit}
            shadow
          >
            <CameraIcon />
          </StyledIconButton>
          <UploadImage
            shape="round"
            visible={isEditAvatar}
            action="/users/picture"
            onClose={handleCloseEdit}
            onChange={handleChangePicture}
          />
        </EditAvatarRoot>
      )}
    </AvatarRoot>
  );
}

const AvatarRoot = styled.div`
  position: relative;
`;

const EditAvatarRoot = styled.div`
  position: absolute;
  right: 0px;
  bottom: 0px;
`;

const StyledIconButton = styled(IconButton)`
  filter: drop-shadow(0px 6px 12px rgba(34, 38, 77, 0.08)),
    drop-shadow(0px 1px 3px rgba(34, 38, 77, 0.06)),
    drop-shadow(0px 0px 1px rgba(34, 38, 77, 0.25));
`;
