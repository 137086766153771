import React from 'react';
import { useParams, useLocation, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';

import styled from 'styled-components';

import { NotFoundPage } from 'app/components/common/NotFoundPage';

import Contact from './components/Contact';
import Progress from './components/Progress';
import Header, { getHasHeader } from './components/Header';
import About, { getHasAbout } from './components/About';
import Classes from './components/Classes';
import Education from './components/Education';
import Details, { getHasDetails } from './components/Details';
import FirstTime from './components/FirstTime';
import {
  EditBasicInfo,
  EditTraining,
  EditQualification,
  EditDetailsInfo,
} from './Editor/Loadable';

import useAction from 'utils/useAction';
import { useInjectReducer } from 'utils/redux-injectors';

import { reducer, sliceKey, actions } from './slice';

import {
  selectError,
  selectProfile,
  selectTrainings,
  selectQualifications,
} from './selectors';

import {
  selectIsLogged,
  selectHandle,
  selectUser,
  selectIsAdmin,
} from 'app/containers/Login/selectors';

interface ParamTypes {
  handle?: string;
}

interface LocationTypes {
  isSignUp?: boolean;
}

export default function Profile() {
  const { handle } = useParams<ParamTypes>();
  const location = useLocation<LocationTypes>();

  const [isBasicEdit, setIsBasicEdit] = React.useState(false);
  const [isClassesEdit, setIsClassesEdit] = React.useState(-1);
  const [isQualEdit, setIsQualEdit] = React.useState<any>(null);
  const [isDetailsEdit, setIsDetailsEdit] = React.useState(false);

  useInjectReducer({ key: sliceKey, reducer: reducer });

  const userHandle = useSelector(selectHandle);
  const user = useSelector(selectUser);
  const isLogged = useSelector(selectIsLogged);
  const isAdmin = useSelector(selectIsAdmin);
  const porfileError = useSelector(selectError);
  const profile = useSelector(selectProfile);
  const trainings = useSelector(selectTrainings);
  const diplomas = useSelector(selectQualifications);

  const hasHandle = Boolean(handle);
  const isOwnProfile = hasHandle && handle === userHandle;
  const isEditMode = isOwnProfile || isAdmin;

  const fetchProfile = useAction(actions.fetch);
  const fetchTrainingTopics = useAction(actions.fetchTrainingTopics);

  const handleBasicEdit = () => setIsBasicEdit(true);
  const handleBasicClose = () => setIsBasicEdit(false);
  const handleClassesEdit = index => setIsClassesEdit(index);
  const handleClassesNew = () => setIsClassesEdit(trainings.length);
  const handleClassesClose = () => setIsClassesEdit(-1);
  const handleQualEdit = qual => setIsQualEdit(qual);
  const handleQualClose = () => setIsQualEdit(null);
  const handleQualAdd = () => setIsQualEdit(true);
  const handleDetailsEdit = () => setIsDetailsEdit(true);
  const handleDetailsClose = () => setIsDetailsEdit(false);

  React.useEffect(() => {
    handle && fetchProfile(handle);
    fetchTrainingTopics();
  }, [handle, fetchProfile, fetchTrainingTopics]);

  // trying to access your own profile without being logged
  if (!hasHandle && !isLogged) {
    return <Redirect to="/p/signin" />;
  }

  // trying to access your own profile without the handle
  if (!hasHandle && userHandle) {
    return (
      <Redirect
        to={{
          pathname: `/${userHandle}`,
          state: location.state,
        }}
      />
    );
  }

  // trying to access your own profile before creating it
  if (isLogged && isOwnProfile && !profile) {
    return <Redirect to="/p/create/general" />;
  }

  // trying to access an invalid profile
  if (porfileError) {
    return <NotFoundPage />;
  }

  if (!profile) return null;

  const showProgress = isEditMode && isOwnProfile;

  const hasInfo = getHasHeader(profile) || !isEditMode;
  const isEditStandalone =
    hasInfo && getHasAbout(profile) && getHasDetails(profile);

  return (
    <Root>
      <Helmet>
        <title>{`${profile.firstName} ${profile.lastName}`}</title>
      </Helmet>
      {!isEditMode && <Contact profile={profile} />}
      {showProgress && (
        <Progress
          profile={profile}
          onBasicEdit={handleBasicEdit}
          onClassesNew={handleClassesNew}
          onClassesEdit={handleClassesEdit}
          onQualAdd={handleQualAdd}
        />
      )}
      <Header
        profile={profile}
        isEditMode={isEditMode}
        onEdit={handleBasicEdit}
      />
      <About profile={profile} isEditMode={isEditMode} />
      <Classes
        trainings={trainings}
        isEditMode={isEditMode}
        onEdit={handleClassesEdit}
        onNew={handleClassesNew}
      />
      <Education
        diplomas={diplomas}
        isEditMode={isEditMode}
        onEdit={handleQualEdit}
        onAdd={handleQualAdd}
      />
      <Details
        profile={profile}
        isEditMode={isEditMode}
        onEdit={handleDetailsEdit}
      />
      <FirstTime
        visible={location.state?.isSignUp}
        user={user}
        onEdit={handleBasicEdit}
      />
      <EditBasicInfo
        visible={isBasicEdit}
        onClose={handleBasicClose}
        standalone={isEditStandalone}
      />
      <EditTraining
        visible={isClassesEdit !== -1}
        onClose={handleClassesClose}
        trainingIndex={Math.max(isClassesEdit, 0)}
      />
      <EditQualification
        visible={Boolean(isQualEdit)}
        qualification={(typeof isQualEdit !== 'boolean' && isQualEdit) || null}
        onClose={handleQualClose}
      />
      <EditDetailsInfo
        visible={isDetailsEdit}
        onClose={handleDetailsClose}
        standalone
      />
    </Root>
  );
}

const Root = styled.div`
  width: 100%;
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    margin-bottom: 20px;
  }
  > *:last-child {
    margin-bottom: 0;
  }
`;
