import React from 'react';
import { useSelector } from 'react-redux';

import { times, multiply, repeat } from 'ramda';

import styled from 'styled-components';

import { Slider } from 'antd';

import useAction from 'utils/useAction';

import { actions } from '../slice';

import { selectFilters, selectResults } from '../selectors';

const MIN = 0;
const MAX = 2000;
const STEP = 50;
const MARKS = {
  0: '$0',
  [MAX]: '$2k+',
};
export default function PriceFilter2({ onChange }) {
  const filters = useSelector(selectFilters);
  const results = useSelector(selectResults);
  const [minMax, setMinMax] = React.useState<[number, number]>([0, MAX]);

  const updateFilters = useAction(actions.updateFilters);
  const resetFilters = useAction(actions.resetFilters);

  const handleChange = minMax => {
    setMinMax(minMax);
    onChange(minMax[0], minMax[1]);
    if (minMax[0] === MIN && minMax[1] === MAX) {
      resetFilters(['price']);
    } else {
      updateFilters({ price: { min: minMax[0], max: minMax[1] } });
    }
  };

  React.useEffect(() => {
    if (filters?.price) {
      setMinMax([filters.price.min, filters.price.max]);
    } else {
      setMinMax([MIN, MAX]);
    }
  }, [filters, setMinMax]);

  const slices = React.useMemo(() => {
    const count = MAX / STEP;
    if (!results) return repeat(0, count);
    const limits = times(multiply(STEP), count + 1);
    limits[limits.length - 1] = Number.MAX_SAFE_INTEGER;
    const slices = limits.map((max, i) => {
      return results.reduce((acc, r) => {
        const min = i > 0 ? limits[i - 1] : 0;
        return (
          acc + ((r.priceMin || 0) <= max && min <= (r.priceMax || 0) ? 1 : 0)
        );
      }, 0);
    });
    return slices;
  }, [results]);

  const maxSlice = React.useMemo(() => {
    return slices.reduce((acc, s) => Math.max(acc, s), [0]);
  }, [slices]);

  return (
    <div>
      <GraphRoot>
        {slices.map((s, i) => (
          <GraphBar
            key={i}
            $maxValue={maxSlice}
            $value={s}
            $filteredOut={isFilteredOut(i * STEP, filters)}
          />
        ))}
      </GraphRoot>
      <Slider
        marks={MARKS}
        step={STEP}
        min={MIN}
        max={MAX}
        value={minMax}
        onChange={handleChange}
        range
      />
    </div>
  );
}

const GraphRoot = styled.div`
  padding: 0 2.5px;
  margin-bottom: -18px;
  display: flex;
  align-items: baseline;
  > div {
    margin-right: 1px;
  }
  > div:last-child {
    margin-right: 0;
  }
`;

const GraphBar = styled.div<{
  $value: number;
  $maxValue: number;
  $filteredOut: boolean;
}>`
  background-color: ${props =>
    props.$filteredOut
      ? props.theme.colors.lightGrey
      : props.theme.colors.primary};
  flex-grow: 1;
  height: ${props => (props.$value / props.$maxValue) * 40}px;
`;

function isFilteredOut(slice, filters) {
  if (!filters?.price) return false;
  return slice < filters.price.min || slice > filters.price.max;
}
