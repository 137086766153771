import React from 'react';

export default function GroupSize({ className, style }: any) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        d="M3.75 17.5C3.75 17.0027 3.94754 16.5258 4.29917 16.1742C4.65081 15.8225 5.12772 15.625 5.625 15.625H16.25V2.5H5.625C5.12772 2.5 4.65081 2.69754 4.29917 3.04917C3.94754 3.40081 3.75 3.87772 3.75 4.375V17.5Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.75 17.5V18.125H15"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
