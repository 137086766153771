import styled from 'styled-components';
import {
  typography,
  TypographyProps,
  color,
  ColorProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
  variant,
} from 'styled-system';

import { Typography } from 'antd';
import { TitleProps } from 'antd/lib/typography/Title';
import { TextProps } from 'antd/lib/typography/Text';
import { ParagraphProps } from 'antd/lib/typography/Paragraph';

const typographyComponents = variant({
  prop: 'level',
  variants: {
    1: { fontSize: props => props.fontSizes.h1 },
    2: { fontSize: props => props.fontSizes.h2 },
    3: { fontSize: props => props.fontSizes.h3 },
    4: { fontSize: props => props.fontSizes.h4 },
    5: { fontSize: props => props.fontSizes.h5 },
    body: { fontSize: props => props.fontSizes.body },
    small: { fontSize: props => props.fontSizes.small },
    note: { fontSize: props => props.fontSizes.note },
  },
});

// prettier-ignore
type ZenuTitleProps =
  & TypographyProps
  & ColorProps
  & SpaceProps
  & LayoutProps
  & TitleProps;

export const Title = styled(Typography.Title)<ZenuTitleProps>`
  ${props => props.$textTransform && `text-transform: ${props.$textTransform};`}
  &&& {
    margin: 0;
    ${color}
    ${space}
    ${layout}
    ${typographyComponents}
  }
  &&&& {
    ${typography}
  }
`;

// prettier-ignore
type ZenuTextProps =
  & TypographyProps
  & ColorProps
  & SpaceProps
  & LayoutProps
  & TextProps;

export const Text = styled(Typography.Text)<ZenuTextProps>`
  && {
    ${color}
    ${space}
    ${layout}
    ${typographyComponents}
  }
  &&& {
    ${typography}
  }
`;
Text.defaultProps = {
  level: 'body',
  color: 'inherit',
};

// prettier-ignore
type ZenuParagraphProps =
  & TypographyProps
  & ColorProps
  & SpaceProps
  & LayoutProps
  & ParagraphProps;

export const Paragraph = styled(Typography.Paragraph)<ZenuParagraphProps>`
  && {
    margin: 0;
    ${color}
    ${space}
    ${layout}
    ${typographyComponents}
  }
  &&& {
    ${typography}
  }
`;
Paragraph.defaultProps = {
  level: 'body',
  color: 'inherit',
};
