import makeFetch, { setToken } from '../../../utils/makeFetch';

import { actions } from './slice';

export function callDeleteAccount({ dispatch }) {
  return async () => {
    await makeFetch('users', { method: 'DELETE' })();
    dispatch(actions.logout());
  };
}

export function saveToken(token: string | null) {
  if (token === null) {
    localStorage.removeItem('token');
  } else {
    localStorage.setItem('token', token);
  }
  setToken(token);
}

export function loadToken() {
  const token = localStorage.getItem('token');
  setToken(token);
  return token;
}

export function subscribe() {
  return async (email: string) => {
    const send = makeFetch(`users/subscribe/${email}`, { method: 'PUT' });
    return send();
  };
}

export function unsubscribe() {
  return async (email: string) => {
    const send = makeFetch(`users/unsubscribe/${email}`, { method: 'PUT' });
    return send();
  };
}

export function registerLocalApi(
  firstName: string,
  lastName: string,
  email: string,
  password: string,
) {
  const body = {
    firstName,
    lastName,
    email,
    password,
  };
  const register = makeFetch(`auth/local/register`, { method: 'POST', body });
  return register();
}

export function loginLocalApi(email: string, password: string) {
  const body = {
    email,
    password,
  };
  const login = makeFetch(`auth/local/login`, { method: 'POST', body });
  return login();
}

export function callResendVerification() {
  return async () => {
    await makeFetch('auth/local/resend', { method: 'POST' })();
  };
}

export function callResetPassword() {
  return async (email: string) => {
    await makeFetch('auth/local/reset', { method: 'POST', body: { email } })();
  };
}

export function callSetNewPassword() {
  return async (password: string, token: string) =>
    await makeFetch('auth/local/set', {
      method: 'POST',
      body: { password, token },
    })();
}
