import React from 'react';

import styled from 'styled-components';

import { Progress as AntProgress, Steps } from 'antd';

import { themedSize } from 'styles/utils';
import { media } from 'styles/media';
import theme from 'theme';

import { Title, Text } from 'app/components/common/Typography';
import Divider from 'app/components/common/Divider';
import CircledArrowRightIcon from 'app/components/icons/CircledArrowRight';

import { SectionBase } from './Section';
import { getHasAbout } from './About';
import { getHasHeader } from './Header';
import { getHasClasses } from './Classes';
import { getHasQualifications } from './Education';

export default function Progress({
  profile,
  onBasicEdit,
  onClassesNew,
  onClassesEdit,
  onQualAdd,
}) {
  const [currentStep, setCurrentStep] = React.useState(0);

  const hasAbout = getHasAbout(profile);
  const hasHeader = getHasHeader(profile);
  const hasDescription = hasAbout || hasHeader;
  const hasClasses = getHasClasses(profile);
  const hasQualifications = getHasQualifications(profile);
  const classCount = profile?.trainings?.length || 0;
  const progress =
    25 +
    (hasDescription ? 25 : 0) +
    (hasClasses ? 25 : 0) +
    (hasQualifications ? 25 : 0);

  if (progress === 100) return null;

  const handleStepChange = index => {
    setCurrentStep(index);
    if (index === 0) {
      onBasicEdit();
    } else if (index === 1) {
      if (classCount === 0) {
        onClassesNew();
      } else {
        onClassesEdit(0);
      }
    } else if (index === 2) {
      onQualAdd();
    }
  };

  return (
    <StyledSection top="navBar" $noPad>
      <TopContent>
        <Title level="body" mb="20px">
          Profile completion
        </Title>
        <AntProgress
          type="circle"
          width={155}
          strokeColor={theme.colors.primary}
          percent={progress}
          format={percent => (
            <div style={{ display: 'grid' }}>
              <Title level={1}>{`${percent}% `}</Title>
              <Text level="note">complete</Text>
            </div>
          )}
        />
      </TopContent>
      <Divider />
      <BottomContent>
        <StyledSteps
          current={currentStep}
          onChange={handleStepChange}
          direction="vertical"
        >
          <Steps.Step
            title="Description"
            disabled={hasDescription}
            status={
              hasDescription ? 'finish' : currentStep === 0 ? 'process' : 'wait'
            }
            description={
              <div>
                Tell us more about yourself.
                <CircledArrowRightIcon />
              </div>
            }
          />
          <Steps.Step
            title="Classes"
            disabled={hasClasses}
            status={
              hasClasses ? 'finish' : currentStep === 1 ? 'process' : 'wait'
            }
            description={
              <div>
                What would you like to teach?
                <CircledArrowRightIcon />
              </div>
            }
          />
          <Steps.Step
            title="Education"
            disabled={hasQualifications}
            status={
              hasQualifications
                ? 'finish'
                : currentStep === 2
                ? 'process'
                : 'wait'
            }
            description={
              <div>
                Show off your qualifications.
                <CircledArrowRightIcon />
              </div>
            }
          />
        </StyledSteps>
      </BottomContent>
    </StyledSection>
  );
}

const StyledSection = styled(SectionBase)<{ top: string }>`
  display: none;

  position: fixed;
  z-index: 1;
  ${themedSize('top')}
  left: calc(50% + 370px + 20px);
  transform: translate(0, 32px);

  width: 235px;

  box-shadow: 0px 12px 24px rgba(34, 38, 77, 0.12),
    0px 1px 3px rgba(34, 38, 77, 0.06), 0px 0px 1px rgba(34, 38, 77, 0.25);

  ${media.medium`
    display: block;
  `}
`;

const TopContent = styled.div`
  padding: 24px 20px 0;
  text-align: center;

  > h1 {
    width: 100%;
    text-align: left;
  }
`;

const BottomContent = styled.div`
  padding: 0 20px 24px;

  span[role='img'] {
    vertical-align: baseline;
  }
`;

const StyledSteps = styled(Steps)`
  &&&& {
    .ant-steps-item-content {
      padding-bottom: 24px;

      .ant-steps-item-title {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
      }

      .ant-steps-item-description {
        font-size: 13px;
        line-height: 17px;

        svg {
          width: 21px;
          height: 21px;
        }
      }
    }
  }
`;

const StyledStep = styled(Steps.Step)<any>`
  ${props => props.$complete && `span { display: none; }`}
`;
