import { call, put, take, takeLeading, select } from 'redux-saga/effects';
import ReactGA from 'react-ga';

import makeFetch, { setToken } from 'utils/makeFetch';

import { actions as profileActions } from 'app/containers/Profile/slice';
import { actions } from './slice';

import {
  loadToken,
  saveToken,
  registerLocalApi,
  loginLocalApi,
} from './service';

import { selectProfile } from 'app/containers/Profile/selectors';

function* loginFlow(action) {
  const token = action.payload || loadToken();
  setToken(token);
  if (!token) {
    yield put(actions.setIsAuthenticating(false));
    return;
  }
  yield put(actions.setIsAuthenticating(true));
  yield call(authorize);
  yield put(actions.setIsAuthenticating(false));
  yield take([actions.logout.type, 'LOGIN_ERROR']);
  saveToken(null);
}

function* authorize() {
  try {
    const fetchUser = makeFetch('users');
    const { success, user, token } = yield call(fetchUser);

    if (success) {
      saveToken(token);
      const existingProfile = yield select(selectProfile);
      if (!existingProfile) {
        yield put(profileActions.setProfile(user));
      }
      yield put(actions.setUser(user));
      yield put(actions.setToken(token));

      ReactGA.set({ userId: user.id });

      return user;
    } else {
      saveToken(null);
      ReactGA.set({ userId: null });
    }
  } catch (error) {
    console.error(error);
    saveToken(null);
    yield put({ type: 'LOGIN_ERROR', error });
  }
}

function* registerLocal(action) {
  try {
    const { firstName, lastName, email, password } = action.payload;
    const { token, success, error } = yield call(
      registerLocalApi,
      firstName,
      lastName,
      email,
      password,
    );
    if (success) {
      yield call(loginFlow, { payload: token });
    } else {
      yield put(actions.setErrors(error));
    }
  } catch (error) {
    console.error(error);
    saveToken(null);
    yield put({ type: 'LOGIN_ERROR', error });
  }
}

function* loginLocal(action) {
  try {
    const { email, password } = action.payload;
    const { success, token, error } = yield call(
      loginLocalApi,
      email,
      password,
    );
    if (success) {
      yield call(loginFlow, { payload: token });
    } else {
      yield put(actions.setErrors(error));
    }
  } catch (error) {
    console.error(error);
    saveToken(null);
    yield put({ type: 'LOGIN_ERROR', error });
  }
}

function* logout() {
  saveToken(null);
}

export default function* saga() {
  yield takeLeading(actions.login.type, loginFlow);
  yield takeLeading(actions.registerLocal.type, registerLocal);
  yield takeLeading(actions.loginLocal.type, loginLocal);
  yield takeLeading(actions.logout.type, logout);
}
