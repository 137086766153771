import React from 'react';

import styled from 'styled-components';

import { Title } from 'app/components/common/Typography';

type SectionProps = {
  children: any;
  title?: string;
  className?: string;
  noPad?: boolean;
  overflow?: boolean;
};
export default function Section({
  children,
  title,
  noPad,
  overflow,
  className,
}: SectionProps) {
  return (
    <Root className={className} $noPad={noPad} $overflow={overflow}>
      {title && (
        <Title level="body" mb="12px">
          {title}
        </Title>
      )}
      {children}
    </Root>
  );
}

export const SectionBase = styled.div<{
  $noPad?: boolean;
  $overflow?: boolean;
}>`
  ${props =>
    !props.$noPad &&
    `
    padding: 24px 20px;
  `}

  ${props =>
    !props.$overflow &&
    `
    overflow: hidden;
  `}

  background-color: white;
  border: 1px solid #e4e6e8;
  border-radius: 20px;

  color: #6a7581;
`;

const Root = styled(SectionBase)`
  position: relative;
  width: 100%;
  max-width: 740px;
  ${props => !props.$noPad && `padding: 24px 20px;`}

  background-color: white;
  border: 1px solid #e4e6e8;
  border-radius: 20px;

  color: #6a7581;
`;
