import { variant } from 'styled-system';

// const theme = {
//   navBar: {
//     size: [ 60, null, 70, 80, null ],
//   },
// };
// const NavBar = styled.div<{height: string; offset: string}>`
//   ...styles
//   ${themedSize('height')}
//   ${themedSize('offset', 'paddingTop')}
// `;
// const MyComp = () => <NavBar height="navBar" offset="navBar" />;
export const themedSize = (propName: string, cssName?: string) => props =>
  variant({
    prop: propName,
    variants: {
      [props[propName]]: {
        [cssName || propName]: props2 => props2[props[propName]].size,
      },
    },
  });
