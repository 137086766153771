import React from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop({ children }) {
  const { pathname } = useLocation();
  const childRef = React.useRef<any>();

  const child = React.Children.only(children);

  React.useEffect(() => {
    if (childRef.current.scrollTo) {
      childRef.current.scrollTo(0, 0);
    } else {
      console.warn(
        `Trying to scroll a Component (${childRef.current.constructor.name}) instead of a DOM element. Use forwardRef on Component`,
      );
    }
  }, [pathname, childRef]);

  const cloned = React.cloneElement(child, { ref: childRef });

  return cloned;
}
