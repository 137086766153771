import React from 'react';

export default function Search({ className }: any) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9.06242 15.625C12.6868 15.625 15.6249 12.6869 15.6249 9.0625C15.6249 5.43813 12.6868 2.5 9.06242 2.5C5.43806 2.5 2.49992 5.43813 2.49992 9.0625C2.49992 12.6869 5.43806 15.625 9.06242 15.625Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7025 13.7031L17.4995 17.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
