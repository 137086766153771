import React from 'react';

import { useCookies } from 'react-cookie';

import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';

import Button from 'app/components/common/Button';
import { Text } from 'app/components/common/Typography';

const CONSENT_COOKIE_NAME = 'zenuCookiesConsent';
const CONSENT_COOKIE_MAX_AGE = 365 * 24 * 60 * 60; // one year

export default function CookieBanner() {
  const [cookies, setCookie] = useCookies([CONSENT_COOKIE_NAME]);
  const isVisible = !Boolean(cookies.zenuCookiesConsent);

  const handleClick = () =>
    setCookie(CONSENT_COOKIE_NAME, true, { maxAge: CONSENT_COOKIE_MAX_AGE });

  return (
    <AnimatePresence>
      {isVisible && (
        <Root
          initial={{ opacity: 0, y: 200 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 200 }}
        >
          <Text m="12px">
            This site uses cookies. By continuing to use this website, you agree
            to their uses.
          </Text>
          <Button
            variant="bgAccent"
            color="primary"
            size="small"
            onClick={handleClick}
            m="12px"
          >
            I understand
          </Button>
        </Root>
      )}
    </AnimatePresence>
  );
}

const Root = styled(motion.div)<any>`
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.textSecondary};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;
