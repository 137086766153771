import React from 'react';
import { useSelector } from 'react-redux';
import { Route, RouteProps } from 'react-router-dom';
import { selectIsLogged } from '../containers/Login/selectors';

import { SignIn } from 'app/containers/Login';

interface PrivateRouteProps extends RouteProps {}

export default function PrivateRoute({
  children,
  path,
  ...rest
}: PrivateRouteProps) {
  const isLogged = useSelector(selectIsLogged);

  const component = isLogged ? (
    children
  ) : (
    // <Redirect to={{ pathname: '/p/signin', state: { from: rest.location } }} />
    <SignIn />
  );
  return <Route {...rest}>{component}</Route>;
}
