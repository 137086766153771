import React, { FunctionComponent } from 'react';

import styled from 'styled-components';
import { variant, buttonStyle, space, SpaceProps } from 'styled-system';

import { ThemeType } from '../../../theme';

type VariantType = 'contained' | 'outlined' | 'text';
type SizeType = 'small' | 'medium' | 'large';

type IconButtonProps = SpaceProps<ThemeType> & {
  variant?: VariantType;
  color?: string;
  size?: SizeType;
};

const IconButton: FunctionComponent<IconButtonProps> = props => {
  return <StyledButton {...props} />;
};

IconButton.defaultProps = {
  variant: 'contained',
  color: 'default',
};

export default IconButton;

const StyledButton = styled.button<IconButtonProps>`
  appearance: none;
  outline: 0;
  cursor: pointer;

  border-radius: 50%;
  border: 1px solid #e5e5e5;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  > .anticon {
    margin: 0;
    > * {
      width: 100%;
      height: 100%;
    }
  }

  > * {
    width: 100%;
    height: 100%;
  }

  ${props => props.shadow && 'box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);'}

  & {
    ${props => sizes[props.size] || sizes.medium}
  }

  & {
    ${props => getColorVariant(props.variant)}
    ${buttonStyle}
  }
  ${space}
`;

const sizes = {
  small: `
    width: 24px;
    height: 24px;
    padding: 5.5px;
  `,
  medium: `
    padding: 6px;
    height: 32px;
    width: 32px;
  `,
  large: `
    padding: 12px;
    height: 48px;
    width: 48px;
  `,
  xlarge: `
    width: 56px;
    height: 56px;
    padding: 16px;
  `,
};

function getColorVariant(prop) {
  const key = colorVariantKeys[prop] || 'default';
  return variant({ prop: 'color', key });
}

const colorVariantKeys = {
  contained: 'buttonContainedColors',
  outlined: 'buttonOutlinedColors',
  text: 'buttonTextColors',
};
