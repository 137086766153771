import React from 'react';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { Space, Select, Switch } from 'antd';

import useAction from 'utils/useAction';
import { extractCountry } from 'utils/geoLocation';

import { actions } from '../slice';
import useCallAction from 'utils/useCallAction';

import { fetchLocationsApi } from 'app/containers/Profile/service';

import { selectFilters } from '../selectors';

const { Option } = Select;

const EMPTY = {
  city: '',
  country: '',
  isLocalOnly: false,
  isOnSiteOnly: false,
};

export default function ExperienceFilter({ onChange }) {
  const filters = useSelector(selectFilters);
  const [search, setSearch] = React.useState('');
  const [location, setLocation] = React.useState(EMPTY);
  const [locations, setLocations] = React.useState([]);

  const searchLocation = useCallAction(fetchLocationsApi);
  const updateFilters = useAction(actions.updateFilters);
  const resetFilters = useAction(actions.resetFilters);

  const handleSelect = val => {
    const { isLocalOnly, isOnSiteOnly } = location;
    const country = extractCountry(val);
    const newLocation = {
      city: val,
      country: country,
      isLocalOnly,
      isOnSiteOnly,
    };

    setLocation(newLocation);
    onChange(newLocation.city, newLocation.country);
    updateFilters({ location: newLocation });
  };
  const handleClear = () => {
    const { isLocalOnly, isOnSiteOnly } = location;
    setLocation({
      city: '',
      country: '',
      isLocalOnly,
      isOnSiteOnly,
    });
    onChange('', '');
    if (!isLocalOnly && !isOnSiteOnly) {
      resetFilters(['location']);
    } else {
      updateFilters({
        location: { city: '', country: '', isLocalOnly, isOnSiteOnly },
      });
    }
  };

  const handleLocationSearch = val => {
    setSearch(val);
  };

  const handleOnSiteChange = val => {
    const { city, country, isLocalOnly } = location;
    if (!isLocalOnly && val && !city && !country) {
      resetFilters(['location']);
    } else {
      updateFilters({
        location: { city, country, isLocalOnly, isOnSiteOnly: !val },
      });
    }
  };
  const handleLocalChange = val => {
    const { city, country, isOnSiteOnly } = location;
    if (!isOnSiteOnly && val && !city && !country) {
      resetFilters(['location']);
    } else {
      updateFilters({
        location: { city, country, isLocalOnly: !val, isOnSiteOnly },
      });
    }
  };

  React.useEffect(() => {
    if (filters?.location) {
      const { city, country, isLocalOnly, isOnSiteOnly } = filters.location;
      setLocation({ city, country, isLocalOnly, isOnSiteOnly });
    } else {
      setLocation(EMPTY);
    }
  }, [filters, setLocation]);

  React.useEffect(() => {
    const fetchLocation = async () => {
      if (search === '') {
        setLocations([]);
        return;
      }
      const resp = await searchLocation(search);
      setLocations(resp.locations);
    };
    fetchLocation();
  }, [searchLocation, setLocations, search]);

  return (
    <Root direction="vertical" align="center">
      <Select
        showSearch
        placeholder="Training location"
        optionFilterProp="children"
        value={location.city}
        onSearch={handleLocationSearch}
        onSelect={handleSelect}
        onClear={handleClear}
        style={{ width: '100%' }}
        allowClear
      >
        {locations.map(loc => (
          <Option value={loc} key={loc}>
            {loc}
          </Option>
        ))}
      </Select>
      <div>
        <Switch
          checkedChildren="remote allowed"
          unCheckedChildren="on-site only"
          checked={!location.isOnSiteOnly}
          onChange={handleOnSiteChange}
        />
      </div>
      <div>
        <Switch
          checkedChildren="international"
          unCheckedChildren="local only"
          checked={!location.isLocalOnly}
          onChange={handleLocalChange}
        />
      </div>
    </Root>
  );
}

const Root = styled(Space)`
  width: 100%;
  > *:first-child {
    width: 100%;
  }
`;
