import React from 'react';

import styled from 'styled-components';

import { Form, Input } from 'antd';
import { Rule } from 'antd/lib/form';

interface TextInputProps {
  label: string;
  value?: string;
  defaultValue?: string;
  placeholder?: string;
  maxLength?: number;
  onChange?: Function;
  onFocus?: Function;
  onBlur?: Function;
  bordered?: Boolean;
  required?: Boolean;
  password?: Boolean;
  type?: string;
}

export default function TextInput({
  label,
  value,
  defaultValue,
  placeholder,
  maxLength,
  onChange,
  onFocus,
  onBlur,
  required,
  password,
  type,
  bordered = true,
}: TextInputProps) {
  const ref = React.useRef<any>();
  const initialValue = value || defaultValue || '';
  const [currentValue, setCurrentValue] = React.useState(initialValue);
  const [showLabel, setShowLabel] = React.useState(!!initialValue);

  const handleClick = () => ref.current.focus();
  const handleFocus = event => {
    if (onFocus) onFocus();
    setShowLabel(true);
  };
  const handleBlur = event => {
    if (onBlur) onBlur();
    setShowLabel(Boolean(currentValue));
  };
  const handleOnChange = event => {
    if (onChange) onChange(event);

    setCurrentValue(event.target.value);
    if (event.target.value) {
      setShowLabel(true);
    }
  };

  const Component = password ? StyledPassword : StyledInput;

  return (
    <Root>
      <Component
        ref={ref}
        value={currentValue}
        defaultValue={defaultValue}
        placeholder={placeholder}
        maxLength={maxLength}
        onChange={handleOnChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        bordered={bordered}
        type={type}
      />
      <Label $showLabel={showLabel} onClick={handleClick}>
        {label}
        {required && ' *'}
      </Label>
    </Root>
  );
}

type FormTextInputProps = TextInputProps & {
  name: string | number | (string | number)[];
  rules?: Rule[];
};

export function FormTextInput({ name, rules, ...rest }: FormTextInputProps) {
  const isRequired = rules && rules.findIndex((r: any) => r.required) !== -1;
  return (
    <Form.Item name={name} rules={rules}>
      <TextInput {...rest} required={isRequired} />
    </Form.Item>
  );
}

const Root = styled.div<any>`
  position: relative;
  width: 100%;
`;

const StyledInput = styled(Input)<any>`
  height: 40px;
  padding-top: 10px;
  padding-left: 11px;
  border-radius: 8px;
`;

const StyledPassword = styled(Input.Password)<any>`
  height: 40px;
  padding-top: 10px;
  padding-left: 11px;
  border-radius: 8px;
`;

const Label = styled.label<any>`
  position: absolute;
  z-index: 2;
  left: 13px;
  top: 12px;
  color: ${props => props.theme.colors.darkGrey};

  && {
    font-size: 13px;
    line-height: 16px;
    transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    cursor: text;

    ${props =>
      props.$showLabel &&
      `
      top: 2px;
      font-size: 8px;
      cursor: default;
    `}
  }
`;
