import React from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';
import { layout } from 'styled-system';
import { motion, useCycle } from 'framer-motion';

import useDimensions from 'utils/useDimensions';
import { themedSize } from 'styles/utils';

import { Avatar, Dropdown } from 'antd';

import Button from 'app/components/common/Button';

import { Menu, MobileMenu } from './Menu';

import { media } from 'styles/media';

export function Actions({ isLogged, avatar }) {
  if (isLogged) {
    return (
      <NavActions $onlyWide>
        <NavAction key="avatar" noborder>
          <Dropdown overlay={<Menu />} trigger={['click']}>
            <Avatar size={46} src={avatar} />
          </Dropdown>
        </NavAction>
      </NavActions>
    );
  }
  return (
    <NavActions $onlyWide>
      <NavAction key="login" noborder>
        <StyledLink to="/p/signin">
          <Button variant="link">Log in</Button>
        </StyledLink>
      </NavAction>
      <NavAction key="getInTouch" noborder>
        <Link to="/p/create">
          <Button variant="contained" color="secondary" size="small">
            Get in touch
          </Button>
        </Link>
      </NavAction>
    </NavActions>
  );
}

export function MobileActions({ isLogged, avatar, bgColor, scroll }) {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = React.useRef(null);
  const { height } = useDimensions(containerRef);

  return (
    <NavActions $onlyNarrow $isOpen={isOpen}>
      <motion.nav
        initial={false}
        animate={isOpen ? 'open' : 'closed'}
        custom={height}
        ref={containerRef}
      >
        {/* <motion.div className="zenu-nav-background" variants={sidebar} /> */}
        <MenuBackground variants={sidebar} $bgColor={bgColor} top="navBar" />
        <MobileMenu isLogged={isLogged} onClose={() => toggleOpen()} />
        {isLogged && <StyledAvatar size={46} src={avatar} />}
        <MobileButton onClick={() => toggleOpen()} $offset={scroll}>
          <svg width="23" height="23" viewBox="0 0 23 23">
            <Path
              variants={{
                closed: { d: 'M 2 2.5 L 20 2.5' },
                open: { d: 'M 3 16.5 L 17 2.5' },
              }}
            />
            <Path
              d="M 2 9.423 L 20 9.423"
              variants={{
                closed: { opacity: 1 },
                open: { opacity: 0 },
              }}
              transition={{ duration: 0.1 }}
            />
            <Path
              variants={{
                closed: { d: 'M 2 16.346 L 20 16.346' },
                open: { d: 'M 3 2.5 L 17 16.346' },
              }}
            />
          </svg>
        </MobileButton>
      </motion.nav>
    </NavActions>
  );
}

const Path = props => (
  <motion.path
    fill="transparent"
    strokeWidth="1"
    stroke="currentColor"
    strokeLinecap="round"
    {...props}
  />
);

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 100% 0px)`,
    transition: {
      type: 'spring',
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: 'circle(0px at 100% 0px)',
    transition: {
      delay: 0.5,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
};

const NavActions = styled.div<any>`
  height: auto;
  display: ${props => (props.$onlyWide && 'none') || 'flex'};
  position: absolute;
  z-index: 10;
  right: 0;
  top: 0;
  width: 100vw;
  /* height: 100vh; */
  background-color: transparent;
  pointer-events: ${props => (props.$isOpen ? 'all' : 'none')};
  transition: top 0.5s ease-in-out;

  nav {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: calc(100vh + 60px);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  ul,
  li {
    margin: 0;
    padding: 0;
  }

  ul {
    width: 100%;
    padding: 20px;
  }

  li {
    list-style: none;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  ${media.medium`
    display: ${props => (props.$onlyNarrow && 'none') || 'flex'};
    position: relative;
    width: auto;
    height: auto;
    pointer-events: all;
  `}
`;

const MenuBackground = styled(motion.div)<any>`
  position: absolute;
  ${themedSize('top')}
  left: 0;
  bottom: 0;
  width: 100vw;
  ${props => props.theme.navBarColors[props.$bgColor]};
`;

interface NavActionProps {
  noborder?: boolean;
  key?: any;
  minWidth?: any;
}
const NavAction = styled.div<NavActionProps>`
  ${props => !props.noborder && 'border-left: solid 1px;'}
  border-color: ${props => props.theme.navBar.borderColor};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  ${layout}
`;

const MobileButton = styled.button<any>`
  outline: none;
  border: none;
  user-select: none;
  cursor: pointer;
  position: absolute;
  /* top: 19px; */
  top: ${props => `${19 + props.$offset}px`};
  right: 27px;
  width: 23px;
  height: 23px;
  background: transparent;
  pointer-events: all;
  padding: 0;
  border-radius: 0;

  > svg {
    position: absolute;
    left: 0;
    top: 0;
  }

  > span {
    position: absolute;
    top: -13px;
    right: 30px;
  }
`;

const StyledLink = styled(Link)`
  color: inherit;
  :hover {
    color: ${props => props.theme.colors.secondary};
  }
`;

const StyledAvatar = styled(Avatar)`
  position: absolute;
  top: 6px;
  right: 57px;
`;
