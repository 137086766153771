export const APP_NAME = 'Zenu';

export const KNOWN_FOR_LIST = [
  'Creative',
  'Dedicated',
  'Passionate',
  'Caring',
  'Grateful',
  'Innovative',
  'Excited',
  'Motivated',
  'Resourceful',
  'Determined',
  'Empathic',
  'Responsive',
  'Interactive',
  'Illustrative examples',
  'Polished',
  'Organized',
  'Expert',
  'Memorable delivery',
  'Well paced',
  'Clear & Concise',
];

export const WILLING_TO_TRAVEL = ['yes', 'no'];

export const TRAINING_ON_SITE = ['on site', 'remote', 'on site/remote'];

export const PROVIDE_COURSE_FILES = ['depends', 'yes', 'no', 'at a fee'];

export const GROUP_SIZE = [
  'any size',
  '<10',
  '10-20',
  '20-50',
  '50-100',
  '100+',
];

// will find solution to populate the language list
export const LANGUAGE_LIST = [
  'Arabic',
  'Bengali',
  'Bulgarian',
  'Chinese - Cantonese/Yue',
  'Chinese - Mandarin',
  'English',
  'Farsi',
  'Filipino',
  'French',
  'German',
  'Hindi/Urdu',
  'Italian',
  'Japanese',
  'Javanese',
  'Korean',
  'Portuguese',
  'Russian',
  'Spanish',
  'Turkish',
  'Vietnamese',
  'Other',
];

export const TRAINING_TYPES = {
  self: ['Mindfulness', 'Resilience', 'Purpose', 'Unconscious bias', 'Career'],
  interpersonal: [
    'Negotiations',
    'Difficult conversations',
    'Empathy and influence',
    'Equity, diversity and inclusion',
    'Networking',
    'Behavioral science',
    'Coaching & development',
    'Conflict resolution',
    'Feedback',
  ],
};

export const INDUSTRIES = [
  'Accounting',
  'Airlines/Aviation',
  'Alternative Dispute Resolution',
  'Alternative Medicine',
  'Animation',
  'Apparel & Fashion',
  'Architecture & Planning',
  'Arts & Crafts',
  'Automotive',
  'Aviation & Aerospace',
  'Banking & Financial Services',
  'Biotechnology',
  'Broadcast Media',
  'Building Materials',
  'Business Supplies & Equipment',
  'Capital Markets',
  'Chemicals',
  'Civic & Social Organization',
  'Computer & Network Security',
  'Construction',
  'Consumer Electronics',
  'Consumer Goods',
  'Consumer Services',
  'Cosmetics',
  'Dairy',
  'Defense & Space',
  'Design',
  'E-learning',
  'Education Management',
  'Engineering',
  'Entertainment',
  'Environmental Services',
  'Events Services',
  'Executive Office',
  'Facilities Services',
  'Farming',
  'Fine Art',
  'Fishery',
  'Food & Beverages',
  'Food Production',
  'Fundraising',
  'Furniture',
  'Gambling & Casinos',
  'Glass, Ceramics & Concrete',
  'Government Administration',
  'Government Relations',
  'Graphic Design',
  'Health, Wellness & Fitness',
  'Higher Education',
  'Hospital & Health Care',
  'Hospitality',
  'Human Resources',
  'Import & Export',
  'Individual & Family Services',
  'Industrial Automation',
  'Information Services',
  'Information Technology & Services',
  'Insurance',
  'International Affairs',
  'International Trade & Development',
  'Internet',
  'Investment Banking',
  'Investment Management',
  'IT',
  'Judiciary',
  'Law Enforcement',
  'Law Practice',
  'Legal Services',
  'Legislative Office',
  'Leisure, Travel & Tourism',
  'Libraries',
  'Logistics & Supply Chain',
  'Luxury Goods & Jewelry',
  'Machinery',
  'Management Consulting',
  'Maritime',
  'Market Research',
  'Marketing & Advertising',
  'Media & Newspaper',
  'Medical Device',
  'Medical Practice',
  'Mental Health Care',
  'Military',
  'Mining & Metals',
  'Motion Pictures & Film',
  'Museums & Institutions',
  'Music',
  'Nanotechnology',
  'Non-profit Organization Management',
  'Oil & Energy',
  'Online Media',
  'Outsourcing/Offshoring',
  'Package/Freight Delivery',
  'Packaging & Containers',
  'Paper & Forest Products',
  'Performing Arts',
  'Pharmaceuticals',
  'Philanthropy',
  'Photography',
  'Plastics',
  'Political Organization',
  'Primary/Secondary Education',
  'Printing',
  'Professional Training & Coaching',
  'Program Development',
  'Public Policy',
  'Public Relations & Communications',
  'Public Safety',
  'Publishing',
  'Railroad Manufacture',
  'Ranching',
  'Real Estate',
  'Recreational Facilities & Services',
  'Religious Institutions',
  'Renewables & Environment',
  'Research',
  'Restaurants',
  'Retail',
  'Security & Investigations',
  'Semiconductors',
  'Shipbuilding',
  'Sporting Goods',
  'Sports',
  'Staffing & Recruiting',
  'Supermarkets',
  'Telecommunications',
  'Textiles',
  'Think Tanks',
  'Translation & Localization',
  'Transportation/Trucking/Railroad',
  'Utilities',
  'Veterinary',
  'Warehousing',
  'Wholesale',
  'Wine, Spirits & Legal Recreational Substances',
  'Wireless',
  'Writing & Editing',
];
