import React from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { Title } from 'app/components/common/Typography';
import ZenuIcon from 'app/components/icons/Zenu';

import config from 'config';

export default function Zenu({ className }: any) {
  return (
    <Logo to="/" className={className}>
      <ZenuIcon />
      <LogoTitle
        level={4}
        color="inherit"
        fontSize="30px"
        lineHeight="30px"
        mb="4px"
      >
        {`zenu${config.TARGET_ENV !== 'production' ? ' - DEV' : ''}`}
      </LogoTitle>
    </Logo>
  );
}

const Logo = styled(Link)`
  display: flex;
  align-items: center;
  color: inherit;

  :hover {
    color: ${props => props.theme.colors.secondary};
  }
`;

const LogoTitle = styled(Title)`
  display: inline;
  padding-left: 12px;
`;
