import makeFetch from 'utils/makeFetch';

export function searchProfilesApi(subject, topic) {
  const query = `subject=${encodeURIComponent(subject)}${
    topic ? `&topic=${encodeURIComponent(topic)}` : ''
  }`;
  const searchProfiles = makeFetch(`profiles/search?${query}`);
  return searchProfiles();
}

export function fetchTrainingSearchablesApi() {
  return async () => {
    const fetchTrainingSearchables = makeFetch(
      `profiles/trainings/searchables`,
    );
    return fetchTrainingSearchables();
  };
}
