import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState, SubtitleType, ColorType } from './types';

export const initialState: ContainerState = {
  progress: 0,
  subtitle: undefined,
  color: 'default',
  isFullPage: false,
};

const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    setProgress(state, action: PayloadAction<number>) {
      state.progress = action.payload;
    },
    setSubtitle(state, action: PayloadAction<SubtitleType | undefined>) {
      state.subtitle = action.payload;
    },
    setColor(state, action: PayloadAction<ColorType>) {
      state.color = action.payload;
    },
    setIsFullPage(state, action: PayloadAction<boolean>) {
      state.isFullPage = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = systemSlice;
