// export const SERVER_URL =
//   process.env.NODE_ENV === 'production' ? '' : 'http://localhost:5000';
// console.log("USING server url:", SERVER_URL, process.env.NODE_ENV);

//import * as path from 'path';

//const target = process.env.TARGET || 'development';
//const configPath = path.resolve(process.cwd(), `.env.${target}`)

//console.log('*∗* Using env file:', configPath);

// require('dotenv').config({ path: configPath });
require('dotenv').config({ debug: true });

interface Config {
  readonly SERVER: {
    readonly URL: string;
    readonly PORT: number;
  };
  readonly SERVER_URL: string;
  readonly NODE_ENV: string;
  readonly TARGET_ENV: string;
  readonly IS_PRODUCTION: boolean;
  readonly REACT_APP_GIT_SHA: string;
  readonly GOOGLE_ANALYTICS_ID: string;
  readonly SENTRY_DNS: string;
}

const {
  REACT_APP_SERVER_BASE_URL: SERVER_BASE_URL = 'http://localhost',
  REACT_APP_SERVER_PORT: SERVER_PORT = '5000',
  NODE_ENV,
  REACT_APP_TARGET = '',
  REACT_APP_GIT_SHA = '',
} = process.env;

const PORT = parseInt(SERVER_PORT, 10);

const config: Config = {
  SERVER: {
    URL: SERVER_BASE_URL,
    PORT,
  },
  SERVER_URL: PORT === 80 ? SERVER_BASE_URL : `${SERVER_BASE_URL}:${PORT}`,
  NODE_ENV,
  TARGET_ENV: REACT_APP_TARGET,
  IS_PRODUCTION: NODE_ENV === 'production',
  REACT_APP_GIT_SHA,
  GOOGLE_ANALYTICS_ID: 'UA-186022909-1',
  SENTRY_DNS:
    'https://bd55d8bb387144fc88495d7d89d02f6e@o476000.ingest.sentry.io/5514785',
};

console.group('Client config');
console.log(`NODE_ENV: ${config.NODE_ENV}
Environment: ${config.TARGET_ENV}
Git version: ${config.REACT_APP_GIT_SHA}
API server: ${config.SERVER.URL}:${config.SERVER.PORT}`);
console.groupEnd();
// console.log(
//   `===============CLIENT CONFIG==================
// NODE_ENV: ${config.NODE_ENV}
// Environment: ${config.TARGET_ENV}
// Git version: ${config.REACT_APP_GIT_SHA}
// API server: ${config.SERVER.URL}:${config.SERVER.PORT}
// ==============================================`,
// );

export default config;
