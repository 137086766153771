import React from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { Modal } from 'antd';

import { Title, Paragraph } from 'app/components/common/Typography';
import Button from 'app/components/common/Button';

export default function FirstTime({ visible, user, onEdit }) {
  const [isVisible, setIsVisible] = React.useState(visible);

  const handleContinue = () => {
    setIsVisible(false);
    onEdit();
  };

  const handleFinish = () => setIsVisible(false);

  if (!user) return null;

  return (
    <StyledModal visible={isVisible} footer={null} closable={false} width={405}>
      <Title level={2} fontWeight="normal">{`Hi, ${user.firstName}!`}</Title>
      <Paragraph>
        Welcome to your new Zenu profile. Now that you’re here, you can either
        continue filling it out or start perusing the platform just to get a
        feel for it. Either way, it’s nice to have you with us!
      </Paragraph>
      <Button color="primary" onClick={handleContinue} block>
        CONTINUE PROFILE CREATION
      </Button>
      <StyledLink variant="link" onClick={handleFinish}>
        Take me to the platform for now
      </StyledLink>
    </StyledModal>
  );
}

const StyledModal = styled(Modal)<any>`
  .ant-modal-content {
    border-radius: 20px;
    padding: 64px 40px;
  }
  .ant-modal-body {
    padding: 0;
    display: grid;
    grid-gap: 28px;
  }
  text-align: center;
`;

const StyledLink = styled(Button)`
  margin: auto;
  color: ${props => props.theme.colors.textDefault};

  &:hover {
    color: ${props => props.theme.colors.primary};
  }

  > span {
    margin: 0;
    text-decoration: underline;
  }
`;
