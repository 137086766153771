import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

import { Divider } from 'antd';
import { DividerProps } from 'antd/lib/divider';

type LineProps = SpaceProps & DividerProps;

export default styled(Divider)<LineProps>`
  & {
    border-top-color: ${props => props.theme.colors.lightGrey};
    ${space}
  }
  &&::after,
  &&::before {
    border-top-color: ${props => props.theme.colors.lightGrey};
  }
`;
