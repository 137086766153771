import React from 'react';

import { motion } from 'framer-motion';

import styled from 'styled-components';

import { Avatar, Tooltip } from 'antd';

import { Paragraph, Text } from 'app/components/common/Typography';

import Details from './Details';

export default function ResultProfile({ profile }) {
  const {
    avatar,
    handle,
    firstName,
    lastName,
    location,
    headline,
    created,
  } = profile;

  const isNew = React.useMemo(() => {
    const date = new Date(created);
    const now = new Date();
    return date.getTime() - now.getTime() < 30 * 24 * 60 * 60 * 1000;
  }, [created]);

  return (
    <a
      href={`${window.location.origin}/${handle}`}
      target="_blank"
      rel="noopener noreferrer"
      style={{ width: '100%', maxWidth: 572 }}
    >
      <Root variants={variants} whileHover={{ scale: 1.04 }}>
        <StyledAvatar src={avatar} size={100} />
        <Description>
          <NameTitle
            firstName={firstName}
            lastName={lastName}
            location={location}
          />
          <Headline>{headline}</Headline>
          <Details profile={profile} />
        </Description>
        {isNew && (
          <NewBadge>
            <Text color="textPrimary" fontSize="10px" lineHeight="10px">
              NEW
            </Text>
          </NewBadge>
        )}
      </Root>
    </a>
  );
}

const Root = styled(motion.div)`
  position: relative;
  background: #ffffff;
  border-radius: 20px;
  max-width: 572px;
  width: 100%;
  height: 148px;
  display: flex;
  color: ${props => props.theme.colors.textDefault};
`;

const variants = {
  hidden: { y: 50, opacity: 0 },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const StyledAvatar = styled(Avatar)`
  margin: 24px 20px 24px 24px;
  flex: 0 0 auto;
`;

const Description = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding: 26px 42px 25px 0;
  overflow-x: hidden;
`;

function NameTitle({ firstName, lastName, location }) {
  return (
    <Text level="small" color="#323a3f">
      <Text level="small" color="primary">{`${firstName} ${lastName}`}</Text>
      {`${location ? ` from ${location}` : ''}`}
    </Text>
  );
}

function Headline({ children }) {
  const [truncated, setTruncated] = React.useState(false);
  return (
    <Tooltip title={truncated ? children : undefined}>
      <span>
        <Paragraph ellipsis={{ onEllipsis: setTruncated }} fontWeight="bold">
          {children}
        </Paragraph>
      </span>
    </Tooltip>
  );
}

const NewBadge = styled.div`
  position: absolute;
  height: 16px;
  top: 12px;
  right: 12px;
  padding: 0px 8px;
  border-radius: 8px;
  background-color: ${props => props.theme.colors.primary};
  display: flex;
  align-items: center;
`;
