import React from 'react';

import styled from 'styled-components';
import { space } from 'styled-system';

import { Text } from 'app/components/common/Typography';
import CircledPlusIcon from 'app/components/icons/CircledPlus';

export default function EmptyBox({ children, onClick, ...rest }) {
  return (
    <Root {...rest} onClick={onClick}>
      <Text level="note" lineHeight={1}>
        {children}
      </Text>
      <CircledPlus />
    </Root>
  );
}

const Root = styled.div`
  background-color: #f3f4f5;
  border: 1px dashed #b3b9bf;
  border-radius: 12px;
  padding: 16px;
  color: #4e575f;
  cursor: pointer;

  ${space}
`;

const CircledPlus = styled(CircledPlusIcon)`
  margin-left: 4px;
`;
