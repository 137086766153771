import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState, TrainingType } from './types';

export const initialState: ContainerState = {
  error: undefined,
  profile: undefined,
  trainingTopics: undefined,
  ui: {
    processingRefCount: 0,
  },
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setIsProcessing(state, action: PayloadAction<boolean>) {
      const isProcessing = action.payload;
      const processingRefCount =
        state.ui.processingRefCount + (isProcessing ? 1 : -1);
      state.ui = {
        ...state.ui,
        processingRefCount,
      };
    },
    fetch(state) {
      state.error = undefined;
    },
    fetchTrainingTopics() {},
    setError(state, action) {
      state.error = action.payload;
    },
    setProfile(state, action) {
      const user = action.payload;
      const profile = user.profile;
      delete user.profile;
      state.profile = profile
        ? {
            ...(state.profile || []),
            ...user,
            ...profile,
            industries: profile?.industries || [],
          }
        : state.profile;
    },
    setTraining(state, action) {
      if (!state.profile) {
        throw new Error('Cannot set training before creating profile');
      }

      const training = action.payload;

      if (!state.profile?.trainings) {
        state.profile.trainings = [];
      }

      const trainings = [...state.profile.trainings];
      const index = trainings.findIndex(t => t.id === training.id);
      if (index === -1) {
        trainings.push(training);
      } else {
        trainings[index] = training;
      }

      state.profile = { ...state.profile, trainings };
    },
    setQualification(state, action) {
      if (!state.profile) {
        throw new Error('Cannot set training before creating profile');
      }

      const qualification = action.payload;

      if (!state.profile?.qualifications) {
        state.profile.qualifications = [];
      }

      const qualifications = [...state.profile.qualifications];
      const index = qualifications.findIndex(t => t.id === qualification.id);
      if (index === -1) {
        qualifications.push(qualification);
      } else {
        qualifications[index] = qualification;
      }

      state.profile = { ...state.profile, qualifications };
    },
    setTrainingTopics(state, action) {
      state.trainingTopics = action.payload;
    },
    updateProfile(state, action) {
      state.profile = { ...state.profile, ...action.payload };
    },
    addTraining(
      state,
      action: PayloadAction<{
        training: TrainingType;
        attach: string[];
        detach: string[];
      }>,
    ) {
      if (!state.profile) {
        throw new Error('Cannot add training before creating profile');
      }
    },
    modTraining(
      state,
      action: PayloadAction<{
        training: TrainingType;
        attach: string[];
        detach: string[];
      }>,
    ) {
      if (!state.profile) {
        throw new Error('Cannot modify training before creating profile');
      }
      if (!action.payload.training.id) {
        throw new Error('Cannot modify training without an id');
      }
    },
    delTraining(state, action: PayloadAction<string>) {
      if (!state.profile) {
        throw new Error('Cannot modify training before creating profile');
      }
      const newTrainings = [...state.profile.trainings];
      const index = newTrainings.findIndex(({ id }) => id === action.payload);
      newTrainings.splice(index, 1);
      state.profile.trainings = newTrainings;
    },
    addQualification(state, action) {
      if (!state.profile) {
        throw new Error('Cannot add qualification before creating profile');
      }
    },
    modQualification(state, action) {
      if (!state.profile) {
        throw new Error('Cannot modify qualification before creating profile');
      }
      if (!action.payload?.qualification?.id) {
        throw new Error('Cannot modify qualification without an id');
      }
    },
    delQualification(state, action: PayloadAction<string>) {
      if (!state.profile) {
        throw new Error('Cannot delete qualification before creating profile');
      }
      const newQualifications = [...state.profile.qualifications];
      const newQualificationIds = [
        ...state.profile.qualificationIds.filter(id => id !== action.payload),
      ];

      const index = newQualifications.findIndex(
        ({ id }) => id === action.payload,
      );
      newQualifications.splice(index, 1);

      const newTrainings = state.profile.trainings.map(t => {
        if (t.qualificationIds.indexOf(action.payload) === -1) {
          return t;
        }
        return {
          ...t,
          qualificationIds: t.qualificationIds.filter(
            id => id !== action.payload,
          ),
        };
      });

      state.profile.trainings = newTrainings;
      state.profile.qualifications = newQualifications;
      state.profile.qualificationIds = newQualificationIds;
    },
    attachQualification(state, action) {
      if (!state.profile) {
        throw new Error('Cannot attach qualification before creating profile');
      }

      const { trainingId, qualificaitonId } = action.payload;
      const trainings = [...state.profile.trainings];
      const index = trainings.findIndex(t => t.id === trainingId);
      const training = { ...trainings[index] };
      training.qualificationIds = [
        ...training.qualificationIds,
        qualificaitonId,
      ];
      trainings[index] = training;

      const qualifications = attachQualificationTraining(
        trainingId,
        qualificaitonId,
        state.profile,
      );

      state.profile = { ...state.profile, trainings, qualifications };
    },
    detachQualification(state, action) {
      if (!state.profile) {
        throw new Error('Cannot detach qualification before creating profile');
      }

      const { trainingId, qualificaitonId } = action.payload;
      const trainings = [...state.profile.trainings];
      let index = trainings.findIndex(t => t.id === trainingId);
      const training = { ...trainings[index] };
      training.qualificationIds = training.qualificationIds.filter(
        id => id !== qualificaitonId,
      );
      trainings[index] = training;

      const qualifications = detachQualificationTraining(
        trainingId,
        qualificaitonId,
        state.profile,
      );

      state.profile = { ...state.profile, trainings, qualifications };
    },
  },
});

export const { actions, reducer, name: sliceKey } = profileSlice;

function attachQualificationTraining(trainingId, qualificationId, profile) {
  const qualifications = [...profile.qualifications];
  const index = qualifications.findIndex(q => q.id === qualificationId);
  const qualification = {
    ...qualifications[index],
    trainingIds: [...(qualifications[index].trainingIds || []), trainingId],
  };
  qualifications[index] = qualification;
  return qualifications;
}

function detachQualificationTraining(trainingId, qualificationId, profile) {
  const qualifications = [...profile.qualifications];
  const index = qualifications.findIndex(q => q.id === qualificationId);
  const trainingIds = (qualifications[index].trainingIds || []).filter(
    id => id !== trainingId,
  );
  const newQual = { ...qualifications[index], trainingIds };
  qualifications[index] = newQual;

  return qualifications;
}
