import React from 'react';

import styled from 'styled-components';

import EditFilled from '@ant-design/icons/EditFilled';

import IconButton from 'app/components/common/IconButton';

interface EditButtonProps {
  isEditMode: boolean;
  onEdit: Function;
  icon: any;
  right?: number;
}
export default function EditButton({
  isEditMode,
  onEdit,
  icon: Icon,
  right,
}: EditButtonProps) {
  if (!isEditMode) return null;

  return (
    <EditActionRoot $right={right}>
      <IconButton size="small" variant="text" onClick={onEdit}>
        <Icon />
      </IconButton>
    </EditActionRoot>
  );
}
EditButton.defaultProps = {
  icon: EditFilled,
};

const EditActionRoot = styled.div<{ $right }>`
  position: absolute;
  right: ${props => (props.$right && `${props.$right}px`) || '12px'};
  top: ${props => (props.$right && '0') || '12px'};
  color: ${props => props.theme.colors.textDefault};
`;
