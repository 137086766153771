import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export default function useAction(action) {
  const dispatch = useDispatch();
  return useCallback((...args) => dispatch(action(...args)), [
    action,
    dispatch,
  ]);
}
