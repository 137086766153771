import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import styled from 'styled-components';

import { Select } from 'antd';

import IconButton from 'app/components/common/IconButton';
import Empty from 'app/components/common/Empty';
import SearchIcon from 'app/components/icons/Search';

import useAction from 'utils/useAction';
import useCallAction from 'utils/useCallAction';

import { actions } from 'app/containers/Search/slice';
import { fetchTrainingSearchablesApi } from 'app/containers/Search/service';

export default function Search() {
  const location = useLocation();
  const history = useHistory();
  const [searchOptions, setSearchOptions] = React.useState([]);
  const [selectedOption, setSelectedOption] = React.useState<any>();

  const bgColor = useSelector(state => (state as any).system.color);

  const search = useAction(actions.search);
  const fetchTrainingSearchables = useCallAction(fetchTrainingSearchablesApi);

  const handleSearch = async option => {
    await search(option || selectedOption);
    if (location.pathname !== '/p/search') {
      history.push('/p/search');
    }
  };
  const handleSelect = val => {
    if (!val) return;
    const option = JSON.parse(val);
    setSelectedOption(option);
    handleSearch(option);
  };
  const handleFocus = () => {
    if (searchOptions && searchOptions.length > 0) return;
    fetchTrainingSearchables().then(({ results }) => {
      setSearchOptions(formatSearchables(results));
    });
  };

  React.useEffect(() => {
    if (location.pathname !== '/p/search') {
      setSearchOptions([]);
      setSelectedOption(undefined);
    }
  }, [location]);

  return (
    <Root $bgColor={bgColor}>
      <StyledInput
        placeholder="Search by training topic or field"
        notFoundContent={<Empty />}
        onSelect={handleSelect}
        onFocus={handleFocus}
        value={JSON.stringify(selectedOption)}
        options={searchOptions}
        optionFilterProp="label"
        showArrow={false}
        showSearch
        $bgColor={bgColor}
        autoFocus={location.pathname !== '/'}
      />
      <IconButton color="secondary" size="large">
        <SearchIcon />
      </IconButton>
    </Root>
  );
}

function formatSearchables(results) {
  return results
    ?.map(({ subject, topic, count }) => {
      const label = topic
        ? `${topic} (${subject}) (${count})`
        : `${subject} (${count})`;
      const value = JSON.stringify({ subject, topic });
      return { value, label };
    })
    .sort((a, b) => (a.label < b.label ? -1 : 1));
}

const Root = styled.div<{ $bgColor: 'default' | 'primary' }>`
  height: 40px;
  line-height: 14px;

  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 100px;

  ${props =>
    props.$bgColor === 'primary' &&
    `
    border: none;
    background: rgba(11, 34, 62, 0.1);
  `}

  ${props =>
    props.$bgColor === 'default' &&
    `
    border: 1px solid #CDD1D6;
    background: transparent;
  `}

  > button {
    flex-shrink: 0;
    height: 32px;
    width: 32px;
    margin: 4px;
    padding: 4px;
  }
`;

const StyledInput = styled(Select)<{ $bgColor: 'default' | 'primary' }>`
  width: calc(100% - 72px);
  padding: 0;
  margin: 0px 16px;
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
  color: ${props =>
    (props.$bgColor === 'primary' && props.theme.colors.textPrimary) ||
    props.theme.colors.textDefault};

  ${props =>
    props.$bgColor === 'primary' &&
    `
    border: none;
    background: rgba(11, 34, 62, 0.1);
  `}

  * {
    box-shadow: none !important;
  }

  && {
    .ant-select-selector {
      background-color: transparent;
      border: none;
    }
  }

  input {
    color: ${props =>
      (props.$bgColor === 'primary' && props.theme.colors.textPrimary) ||
      props.theme.colors.textDefault};
    background-color: transparent !important;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      box-shadow: 0 0 0 30px #0c3838 inset !important;
      -webkit-text-fill-color: ${props =>
        props.theme.colors.textPrimary} !important;
    }
  }
`;
