import React from 'react';

import styled from 'styled-components';

import joinList from 'utils/joinList';

import { Space, Tooltip } from 'antd';

import { Text } from 'app/components/common/Typography';

import CameraIcon from 'app/components/icons/Camera';
import TimerIcon from 'app/components/icons/Timer';
import TakeoffIcon from 'app/components/icons/Takeoff';
import ChatDotsIcon from 'app/components/icons/ChatDots';

import Section from './Section';
import EditButton from './EditButton';

import {
  TRAVEL,
  DELIVERY,
  MATERIAL,
  GROUP_SIZE,
  DURATION,
} from 'app/containers/Profile/constants';

export default function Details({ profile, isEditMode, onEdit }) {
  const hasDetails = getHasDetails(profile);
  if (!hasDetails) return null;

  return (
    <Section title="Details">
      <EditButton isEditMode={isEditMode} onEdit={onEdit} />
      <List>
        <Remote delivery={profile.delivery} />
        <Duration duration={profile.duration} />
        <Travel travel={profile.travel} />
        <Language language={profile.language} />
      </List>
    </Section>
  );
}

export function ShortDetails({ profile }) {
  return (
    <StyledSpace align="end">
      <Remote delivery={profile.delivery} short />
      <Duration duration={profile.duration} short />
      <Travel travel={profile.travel} short />
      <Language language={profile.language} short />
    </StyledSpace>
  );
}

export function getHasDetails(profile) {
  return Boolean(
    profile?.delivery ||
      profile?.duration ||
      profile?.travel ||
      profile?.language,
  );
}

function Remote({ delivery, short }) {
  const doRemote =
    delivery &&
    (delivery.indexOf(DELIVERY[1]) !== -1 ||
      delivery.indexOf(DELIVERY[2]) !== -1);

  if (!doRemote) return null;
  const emphasisColor = short ? undefined : '#323a3f';
  return (
    <Item
      Icon={CameraIcon}
      tooltip={
        <DeliveryText delivery={delivery} emphasisColor={emphasisColor} />
      }
      short={short}
    />
  );
}
Remote.defaultProps = {
  short: false,
};

function Duration({ duration, short }) {
  if (!duration) return null;
  const emphasisColor = short ? undefined : '#323a3f';
  return (
    <Item
      Icon={TimerIcon}
      tooltip={
        <DurationText duration={duration} emphasisColor={emphasisColor} />
      }
      short={short}
    />
  );
}
Duration.defaultProps = {
  short: false,
};

function Travel({ travel, short }) {
  const doTravel = travel && travel.indexOf(TRAVEL[2]) !== -1;
  if (!doTravel) return null;
  const emphasisColor = short ? undefined : '#323a3f';
  return (
    <Item
      Icon={TakeoffIcon}
      tooltip={<TravelText travel={travel} emphasisColor={emphasisColor} />}
      short={short}
    />
  );
}
Travel.defaultProps = {
  short: false,
};

function Language({ language, short }) {
  const emphasisColor = short ? undefined : '#323a3f';
  return (
    <Item
      Icon={ChatDotsIcon}
      tooltip={
        <LanguageText language={language} emphasisColor={emphasisColor} />
      }
      short={short}
    />
  );
}
Language.defaultProps = {
  short: false,
};

function Item({ Icon, tooltip, short }) {
  const handleClick = e => e.preventDefault();
  if (short) {
    return (
      <Tooltip title={tooltip} placement="top" trigger={['hover', 'click']}>
        <ItemIcon onClick={handleClick}>
          <Icon />
        </ItemIcon>
      </Tooltip>
    );
  }
  return (
    <ItemRoot>
      <div>
        <ItemIcon onClick={handleClick}>
          <Icon />
        </ItemIcon>
      </div>
      {tooltip}
    </ItemRoot>
  );
}

const ItemRoot = styled.div`
  clear: left;
  > div {
    height: 100%;
    float: left;
    margin-right: 16px;
  }
`;

const ItemIcon = styled.div`
  border-radius: 50%;
  color: #323a3f;
  background-color: #f3f4f5;
  width: 24px;
  height: 24px;
  text-align: center;
`;

export function TravelText({ travel, emphasisColor }) {
  if (!travel || travel.length === 0) return null;
  const isWilling =
    travel.indexOf(TRAVEL[1]) !== -1 || travel.indexOf(TRAVEL[2]) !== -1;
  const weight = (emphasisColor === 'inherit' && 'bold') || 'normal';
  return (
    <Text fontSize="inherit" lineHeight="18px">
      <Text
        fontSize="inherit"
        fontWeight={weight}
        lineHeight="18px"
        color={emphasisColor}
      >
        {(isWilling && 'Willing') || 'Unwilling'}
      </Text>{' '}
      to travel
    </Text>
  );
}
TravelText.defaultProps = {
  emphasisColor: 'inherit',
};

export function DeliveryText({ delivery, emphasisColor }) {
  if (!delivery || delivery.length === 0) return null;
  const label = getDeliveryLabel(delivery);
  const weight = (emphasisColor === 'inherit' && 'bold') || 'normal';
  return (
    <Text fontSize="inherit" lineHeight="18px">
      Can work
      <Text fontSize="inherit" fontWeight={weight} color={emphasisColor}>
        {` ${label}`}
      </Text>
    </Text>
  );
}
DeliveryText.defaultProps = {
  emphasisColor: 'inherit',
};
function getDeliveryLabel(delivery) {
  const hasOnSite = delivery.indexOf(DELIVERY[0]) !== -1;
  const hasRemote =
    delivery.indexOf(DELIVERY[1]) !== -1 ||
    delivery.indexOf(DELIVERY[2]) !== -1;

  if (hasOnSite && hasRemote) return 'on site or remotely';
  if (hasOnSite) return 'on site';
  if (hasRemote) return 'remotely';

  throw new Error(`Invalid 'delivery' value: ${delivery}`);
}

export function MaterialText({ material, emphasisColor }) {
  if (!material || material.length === 0) return null;
  const label = getMaterialLabel(material, emphasisColor);
  return (
    <Text fontSize="inherit" lineHeight="18px">
      {label}
    </Text>
  );
}
MaterialText.defaultProps = {
  emphasisColor: 'inherit',
};
function getMaterialLabel(material, emphasisColor) {
  const weight = (emphasisColor === 'inherit' && 'bold') || 'normal';
  if (material.indexOf(MATERIAL[3]) !== -1) {
    return (
      <>
        Provides course files
        <Text fontSize="inherit" fontWeight={weight} color={emphasisColor}>
          {' sometimes'}
        </Text>
      </>
    );
  }

  const doesNotProvide = material.indexOf(MATERIAL[0]) !== -1;
  const hasPrint = material.indexOf(MATERIAL[1]) !== -1;
  const hasDigital = material.indexOf(MATERIAL[2]) !== -1;

  if (hasDigital && hasPrint) {
    return (
      <>
        Provides
        <Text fontSize="inherit" fontWeight={weight} color={emphasisColor}>
          {' print and digital '}
        </Text>
        course files
      </>
    );
  }

  if (hasPrint) {
    return (
      <>
        Provides
        <Text fontSize="inherit" fontWeight={weight} color={emphasisColor}>
          {' print '}
        </Text>
        course files
      </>
    );
  }

  if (hasDigital) {
    return (
      <>
        Provides
        <Text fontSize="inherit" fontWeight={weight} color={emphasisColor}>
          {' digital '}
        </Text>
        course files
      </>
    );
  }

  if (doesNotProvide) {
    return (
      <>
        Does
        <Text fontSize="inherit" fontWeight={weight} color={emphasisColor}>
          {' not '}
        </Text>
        provide course files
      </>
    );
  }

  throw new Error(`Invalid 'material' value: ${material}`);
}

export function GroupSizeText({ groupSize, emphasisColor }) {
  if (!groupSize || groupSize.length === 0) return null;
  const label = getGroupSizeLabel(groupSize);
  const weight = (emphasisColor === 'inherit' && 'bold') || 'normal';
  return (
    <Text fontSize="inherit" lineHeight="18px">
      Prefers training groups of
      <Text fontSize="inherit" fontWeight={weight} color={emphasisColor}>
        {` ${label}`}
      </Text>
    </Text>
  );
}
function getGroupSizeLabel(groupSize) {
  if (groupSize.indexOf(GROUP_SIZE[4]) !== -1) return '100 or more people';
  if (groupSize.indexOf(GROUP_SIZE[3]) !== -1) return '50 to 100 people';
  if (groupSize.indexOf(GROUP_SIZE[2]) !== -1) return '30 to 50 people';
  if (groupSize.indexOf(GROUP_SIZE[1]) !== -1) return '10 to 30 people';
  if (groupSize.indexOf(GROUP_SIZE[0]) !== -1) return 'less than 10 people';
  throw new Error(`Invalid 'groupSize' value: ${groupSize}`);
}

export function DurationText({ duration, emphasisColor }) {
  if (!duration || duration.length === 0) return null;
  const label = getDurationLabel(duration);
  const weight = (emphasisColor === 'inherit' && 'bold') || 'normal';
  return (
    <Text fontSize="inherit" lineHeight="18px">
      Will do
      <Text fontSize="inherit" fontWeight={weight} color={emphasisColor}>
        {` ${label}`}
      </Text>{' '}
      assignments
    </Text>
  );
}
DurationText.defaultProps = {
  emphasisColor: 'inherit',
};
function getDurationLabel(duration) {
  const short = duration.indexOf(DURATION[0]) !== -1 && 'short (days)';
  const medium = duration.indexOf(DURATION[1]) !== -1 && 'medium (weeks)';
  const long = duration.indexOf(DURATION[2]) !== -1 && 'long (months)';

  const durations = [short, medium, long].filter(i => !!i);

  if (durations.length === 1) return durations[0];
  if (durations.length === 2) return durations.join(' or ');
  return `${durations[0]}, ${durations[1]} or ${durations[2]}`;
}

export function LanguageText({ language, emphasisColor }) {
  const label = getLanguageLabel(language);
  const weight = (emphasisColor === 'inherit' && 'bold') || 'normal';
  return (
    <Text fontSize="inherit" lineHeight="18px">
      Speaks
      <Text fontSize="inherit" fontWeight={weight} color={emphasisColor}>
        {` ${label}`}
      </Text>{' '}
    </Text>
  );
}
LanguageText.defaultProps = {
  emphasisColor: 'inherit',
};
function getLanguageLabel(language) {
  const count = language?.length;
  if (!count) return 'English';
  return joinList(language);
}

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  > * {
    width: 50%;
    overflow-x: hidden;
    margin: 4px 0;
  }
`;

const StyledSpace = styled(Space)`
  flex-grow: 1;
`;
