import React from 'react';

import joinList from 'utils/joinList';

import { Title, Paragraph } from 'app/components/common/Typography';

import { EditGeneral, EditTrainerInfo } from '../Editor/Loadable';

import Section from './Section';
import EditButton from './EditButton';

export default function About({ profile, isEditMode }) {
  if (!getHasAbout(profile)) {
    return null;
  }

  const [isEdit, setIsEdit] = React.useState(false);
  const handleEdit = () => setIsEdit(true);
  const handleClose = () => setIsEdit(false);

  return (
    <Section title="About me">
      <EditButton isEditMode={isEditMode} onEdit={handleEdit} />
      <EditTrainerInfo visible={isEdit} onClose={handleClose} standalone />
      <Paragraph level="small" lineHeight="133%">
        {profile.summary}
      </Paragraph>
      <Title level="small" mb="8px" mt="24px">
        Industries specialized in
      </Title>
      <Paragraph level="small" lineHeight="133%">
        {joinList(profile.industries?.filter(i => !!i) || [])}
      </Paragraph>
      <Title level="small" mb="8px" mt="24px">
        Feedback most often received
      </Title>
      <Paragraph level="small" lineHeight="133%">
        {joinList(profile.knownFor?.filter(k => !!k) || [])}
      </Paragraph>
      {profile.organization && (
        <>
          <Title level="small" mb="8px" mt="24px">
            Training Organization
          </Title>
          <Paragraph level="small" lineHeight="133%">
            {profile.organization}
          </Paragraph>
        </>
      )}
    </Section>
  );
}

export function getHasAbout(profile) {
  return Boolean(
    profile?.summary ||
      profile?.industries?.length ||
      profile?.knownFor?.length,
  );
}
