import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';

export const initialState: ContainerState = {
  isAuthenticating: true, // app will start by trying to authenticate
  token: undefined,
  user: undefined,
  error: undefined,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    login(state) {
      state.error = undefined;
    },
    registerLocal(state) {
      state.error = undefined;
    },
    loginLocal(state) {
      state.error = undefined;
    },
    setIsAuthenticating(state, action: PayloadAction<boolean>) {
      state.isAuthenticating = action.payload;
    },
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
      if (action.payload) {
        state.error = undefined;
      }
    },
    setUser(state, action) {
      state.user = { ...state.user, ...action.payload };
    },
    logout(state) {
      state.isAuthenticating = false;
      state.token = undefined;
      state.user = undefined;
      state.error = undefined;
    },
    setErrors(state, action) {
      state.error = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = loginSlice;
