import { pick, omit } from 'ramda';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { selectTrainingTopics, selectProfile } from './selectors';
import {
  attachQualificationApi,
  detachQualificationApi,
  addQualificationApi,
  modQualificationApi,
  deleteQualificationApi,
  addProfileTrainingApi,
  modProfileTrainingApi,
  deleteTrainingApi,
  fetchProfileApi,
  fetchTrainingTopicsApi,
  createProfileApi,
  updateProfileApi,
} from './service';
import { actions } from './slice';

function* fetchProfile(action) {
  try {
    yield put(actions.setIsProcessing(true));
    const handle = action.payload;
    const existingProfile = yield select(selectProfile);

    if (existingProfile?.handle === handle) return existingProfile;

    const { success, user, message } = yield call(fetchProfileApi, handle);
    if (success) {
      yield put(actions.setProfile(user));
    } else {
      yield put(actions.setError(message));
    }
    yield put(actions.setIsProcessing(false));
  } catch (error) {
    console.error(error);
    //yield put(actions.setError(error.toString()));
  }
}

function* fetchTrainingTopics(action) {
  try {
    const existingTrainingTopics = yield select(selectTrainingTopics);
    if (existingTrainingTopics) {
      return;
    }
    yield put(actions.setIsProcessing(true));
    const { success, trainingTopics } = yield call(fetchTrainingTopicsApi);
    if (success) {
      yield put(actions.setTrainingTopics(trainingTopics));
    }
    yield put(actions.setIsProcessing(false));
  } catch (error) {
    console.error(error);
  }
}

const USER_FIELDS = ['firstName', 'lastName', 'email'];

function* updateProfile(action) {
  try {
    yield put(actions.setIsProcessing(true));
    const { id } = yield select(selectProfile);

    const payload = action.payload;
    const userData = pick(USER_FIELDS, payload);
    const profileData = omit(USER_FIELDS, payload);

    let result;
    if (!id) {
      result = yield call(createProfileApi, {
        profile: profileData,
        user: userData,
      });
    } else {
      result = yield call(updateProfileApi, id, {
        profile: profileData,
        user: userData,
      });
    }

    if (result.success) {
      if (result.user) {
        yield put(actions.setProfile(result.user));
      }
    } else {
      //TODO: handle error
    }
    yield put(actions.setIsProcessing(false));
  } catch (e) {
    yield put({ type: 'USER_FETCH_FAILED', message: e.message });
  }
}

function* deleteTraining(action) {
  try {
    yield put(actions.setIsProcessing(true));
    const { id: profileId } = yield select(selectProfile);

    const id = action.payload;
    yield call(deleteTrainingApi, profileId, id);
    yield put(actions.setIsProcessing(false));
  } catch (e) {
    yield put({ type: 'USER_FETCH_FAILED', message: e.message });
  }
}

function* modifyTraining(action) {
  try {
    yield put(actions.setIsProcessing(true));
    const { id: profileId } = yield select(selectProfile);

    const { success, training } = yield call(
      modProfileTrainingApi,
      profileId,
      action.payload.training,
    );
    if (success) {
      yield put(actions.setTraining(training));
    }
    yield put(actions.setIsProcessing(false));
  } catch (e) {
    yield put({ type: 'USER_FETCH_FAILED', message: e.message });
  }
}

function* addTraining(action) {
  try {
    yield put(actions.setIsProcessing(true));
    const { id: profileId } = yield select(selectProfile);
    const { success, training } = yield call(
      addProfileTrainingApi,
      profileId,
      action.payload.training,
    );
    if (success) {
      yield put(actions.setTraining(training));
    }
    yield put(actions.setIsProcessing(false));
  } catch (e) {
    yield put({ type: 'USER_FETCH_FAILED', message: e.message });
  }
}

function* addQualification(action) {
  try {
    yield put(actions.setIsProcessing(true));
    const { id: profileId } = yield select(selectProfile);
    const { qualification: qualificationData, attach, detach } = action.payload;
    const { success, qualification } = yield call(
      addQualificationApi,
      profileId,
      qualificationData,
    );
    if (success) {
      yield put(actions.setQualification(qualification));

      const attachList = attach.map(trainingId =>
        put(
          actions.attachQualification({
            trainingId,
            qualificaitonId: qualification.id,
          }),
        ),
      );

      const detachList = detach.map(trainingId =>
        put(
          actions.detachQualification({
            trainingId,
            qualificaitonId: qualification.id,
          }),
        ),
      );

      yield all([...attachList, ...detachList]);
    }
    yield put(actions.setIsProcessing(false));
  } catch (e) {
    yield put({ type: 'USER_FETCH_FAILED', message: e.message });
  }
}

function* modifyQualification(action) {
  try {
    yield put(actions.setIsProcessing(true));
    const { id: profileId } = yield select(selectProfile);
    const { qualification: qualificationData, attach, detach } = action.payload;

    const { success, qualification } = yield call(
      modQualificationApi,
      profileId,
      qualificationData,
    );
    if (success) {
      yield put(actions.setQualification(qualification));

      const attachList = attach.map(trainingId =>
        put(
          actions.attachQualification({
            trainingId,
            qualificaitonId: qualification.id,
          }),
        ),
      );

      const detachList = detach.map(trainingId =>
        put(
          actions.detachQualification({
            trainingId,
            qualificaitonId: qualification.id,
          }),
        ),
      );

      yield all([...attachList, ...detachList]);
    }
    yield put(actions.setIsProcessing(false));
  } catch (e) {
    yield put({ type: 'USER_FETCH_FAILED', message: e.message });
  }
}

function* deleteQualification(action) {
  try {
    yield put(actions.setIsProcessing(true));
    const { id: profileId } = yield select(selectProfile);

    const id = action.payload;
    yield call(deleteQualificationApi, profileId, id);
    yield put(actions.setIsProcessing(false));
  } catch (e) {
    yield put({ type: 'USER_FETCH_FAILED', message: e.message });
  }
}

function* attachQualification(action) {
  try {
    yield put(actions.setIsProcessing(true));
    const { id: profileId } = yield select(selectProfile);

    yield call(
      attachQualificationApi,
      profileId,
      action.payload.trainingId,
      action.payload.qualificaitonId,
    );
    yield put(actions.setIsProcessing(false));
  } catch (e) {
    console.error(e);
    yield put({ type: 'USER_FETCH_FAILED', message: e.message });
  }
}

function* detachQualification(action) {
  try {
    yield put(actions.setIsProcessing(true));
    const { id: profileId } = yield select(selectProfile);

    yield call(
      detachQualificationApi,
      profileId,
      action.payload.trainingId,
      action.payload.qualificaitonId,
    );
    yield put(actions.setIsProcessing(false));
  } catch (e) {
    console.error(e);
    yield put({ type: 'USER_FETCH_FAILED', message: e.message });
  }
}

function* profileSaga() {
  yield takeLatest(actions.fetchTrainingTopics.type, fetchTrainingTopics);
  yield takeLatest(actions.fetch.type, fetchProfile);
  yield takeLatest(actions.updateProfile.type, updateProfile);
  yield takeLatest(actions.addTraining.type, addTraining);
  yield takeLatest(actions.modTraining.type, modifyTraining);
  yield takeLatest(actions.delTraining.type, deleteTraining);
  yield takeLatest(actions.addQualification.type, addQualification);
  yield takeLatest(actions.modQualification.type, modifyQualification);
  yield takeLatest(actions.delQualification.type, deleteQualification);
  yield takeLatest(actions.attachQualification.type, attachQualification);
  yield takeLatest(actions.detachQualification.type, detachQualification);
}

export default profileSaga;
