/**
 * Asynchronously loads the component for Profile Editor
 */

import { lazyLoad } from 'utils/loadable';

export const EditGeneral = lazyLoad(
  () => import('./index'),
  module => module.EditGeneral,
);

export const EditDetails = lazyLoad(
  () => import('./index'),
  module => module.EditDetails,
);

export const EditTopic = lazyLoad(
  () => import('./index'),
  module => module.EditTopic,
);

export const EditBasicInfo = lazyLoad(
  () => import('./index'),
  module => module.BasicInfo,
);

export const EditTrainerInfo = lazyLoad(
  () => import('./index'),
  module => module.TrainerInfo,
);

export const EditDetailsInfo = lazyLoad(
  () => import('./index'),
  module => module.DetailsInfo,
);

export const EditTraining = lazyLoad(
  () => import('./index'),
  module => module.Training,
);

export const EditQualification = lazyLoad(
  () => import('./index'),
  module => module.Qualification,
);
