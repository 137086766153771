import React from 'react';

import styled from 'styled-components';

import { Space, Tooltip } from 'antd';

import CameraIcon from 'app/components/icons/Camera';
import TimerIcon from 'app/components/icons/Timer';
import TakeoffIcon from 'app/components/icons/Takeoff';
import ChatDotsIcon from 'app/components/icons/ChatDots';

import { TRAVEL, DELIVERY } from 'app/containers/Profile/constants';

import {
  DeliveryText,
  DurationText,
  TravelText,
  LanguageText,
} from 'app/containers/Profile/components/Details';

export default function Details({ profile }) {
  const doTravel = profile.travel && profile.travel.indexOf(TRAVEL[2]) !== -1;
  const doRemote =
    profile.delivery &&
    (profile.delivery.indexOf(DELIVERY[1]) !== -1 ||
      profile.delivery.indexOf(DELIVERY[2]) !== -1);
  return (
    <StyledSpace align="end">
      {doRemote && (
        <Item
          Icon={CameraIcon}
          tooltip={<DeliveryText delivery={profile.delivery} />}
        />
      )}
      {profile.duration && (
        <Item
          Icon={TimerIcon}
          tooltip={<DurationText duration={profile.duration} />}
        />
      )}
      {doTravel && (
        <Item
          Icon={TakeoffIcon}
          tooltip={<TravelText travel={profile.travel} />}
        />
      )}
      <Item
        Icon={ChatDotsIcon}
        tooltip={<LanguageText language={profile.language} />}
      />
    </StyledSpace>
  );
}

const StyledSpace = styled(Space)`
  flex-grow: 1;
`;

function Item({ Icon, tooltip }) {
  const handleClick = e => e.preventDefault();
  return (
    <Tooltip title={tooltip} placement="top" trigger={['hover', 'clicl']}>
      <ItemRoot onClick={handleClick}>
        <Icon />
      </ItemRoot>
    </Tooltip>
  );
}

const ItemRoot = styled.div`
  border-radius: 50%;
  background-color: #f3f4f5;
  width: 24px;
  height: 24px;
  text-align: center;
`;
