import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.search || initialState;

export const selectCriteria = createSelector(
  [selectDomain],
  searchState => searchState.criteria,
);

export const selectResults = createSelector(
  [selectDomain],
  searchState => searchState.results,
);

export const selectFilters = createSelector(
  [selectDomain],
  searchState => searchState.filters,
);
