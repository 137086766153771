import React from 'react';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { Collapse } from 'antd';

import { Text } from 'app/components/common/Typography';
import Button from 'app/components/common/Button';
import FiltersIcon from 'app/components/icons/Filters';
import GroupSizeIcon from 'app/components/icons/GroupSize';
import BookIcon from 'app/components/icons/Book';
import MoneyIcon from 'app/components/icons/Money';
import GlobeIcon from 'app/components/icons/Globe';
import ChatDots from 'app/components/icons/ChatDots';

// import PriceFilter2 from './PriceFilter';
import PriceFilter from './PriceFilter2';
import ClassSizeFilter from './ClassSizeFilter';
import ExperienceFilter from './ExperienceFilter';
import LanguageFilter from './LanguageFilter';
import LocationFilter from './LocationFilter';

import { media } from 'styles/media';

import useAction from 'utils/useAction';

import { actions } from '../slice';

import { selectFilters } from '../selectors';

const { Panel } = Collapse;

export default function Filters() {
  const [collapsed, setCollapsed] = React.useState(true);
  const [openList, setOpenList] = React.useState([]);

  const filters = useSelector(selectFilters);
  const resetFilters = useAction(actions.resetFilters);

  const handleCollapse = () => setCollapsed(!collapsed);
  const handlePriceChange = (min, max) => {};
  const handleChange = openList => setOpenList(openList);
  const handleClearFilters = () => resetFilters();

  return (
    <Root $collapsed={collapsed}>
      <Header onClick={handleCollapse} />
      <StyledCollapse expandIconPosition="right" onChange={handleChange}>
        <Panel
          header={
            <PanelHeader
              Icon={MoneyIcon}
              label="Price (US$ per hour)"
              active={filters?.price}
            />
          }
          key="price"
        >
          <PriceFilter onChange={handlePriceChange} />
        </Panel>
        <Panel
          header={
            <PanelHeader
              Icon={GroupSizeIcon}
              label="Class size"
              active={filters?.classSize}
            />
          }
          key="classSize"
        >
          <ClassSizeFilter onChange={handlePriceChange} />
        </Panel>
        <Panel
          header={
            <PanelHeader
              Icon={BookIcon}
              label="Trainer experience"
              active={filters?.experience}
            />
          }
          key="experience"
        >
          <ExperienceFilter
            onChange={handlePriceChange}
            isOpened={isOpened('experience', openList)}
          />
        </Panel>
        <Panel
          header={
            <PanelHeader
              Icon={GlobeIcon}
              label="Training location"
              active={filters?.location}
            />
          }
          key="location"
        >
          <LocationFilter onChange={handlePriceChange} />
        </Panel>
        <Panel
          header={
            <PanelHeader
              Icon={ChatDots}
              label="Languages"
              active={filters?.language}
            />
          }
          key="language"
        >
          <LanguageFilter onChange={handlePriceChange} />
        </Panel>
      </StyledCollapse>
      <ClearButtonRoot>
        <Button onClick={handleClearFilters} block>
          Clear all filters
        </Button>
      </ClearButtonRoot>
    </Root>
  );
}

function isOpened(panelKey, openList) {
  return openList.indexOf(panelKey) !== -1;
}

function Header({ onClick }) {
  return (
    <HeaderRoot onClick={onClick}>
      <Text level="small" color="textPrimary" fontWeight="bold">
        <FiltersIcon style={{ marginRight: '12px' }} />
        Filters
      </Text>
    </HeaderRoot>
  );
}

function PanelHeader({ Icon, label, active }) {
  return (
    <Text
      level="small"
      fontWeight={(active && 700) || 'normal'}
      color={(active && 'primary') || 'textDefault'}
    >
      <Icon style={{ marginRight: '12px' }} />
      {label}
    </Text>
  );
}

const Root = styled.div<{ $collapsed?: boolean }>`
  width: 100%;
  background-color: white;
  transition: height 0.5s ease-in-out;

  ${props =>
    props.$collapsed &&
    `
    height: 60px;
    overflow-y: hidden;
  `}

  ${media.small`
    max-width: 240px;
    height: unset;
  `}
`;

const HeaderRoot = styled.div`
  width: 100%;
  height: 60px;
  padding: 18px 23px;
  background-color: ${props => props.theme.colors.primary};

  ${media.small`
    pointer-events: none;
  `}
`;

const StyledCollapse = styled(Collapse)`
  padding: 8px;
  border: none;
  background-color: transparent;

  .ant-collapse-item {
    background-color: transparent;
    border: none;
  }

  .ant-collapse-content {
    background-color: transparent;
    border: none;
  }

  .ant-collapse-item.ant-collapse-item-active {
    margin-bottom: 18px;
    border-radius: 8px;
    box-shadow: 0px 6px 12px rgba(34, 38, 77, 0.08),
      0px 1px 3px rgba(34, 38, 77, 0.06), 0px 0px 1px rgba(34, 38, 77, 0.25);
  }
`;

const ClearButtonRoot = styled.div`
  padding: 20px;
  > button {
    border-radius: 8px;

    > span {
      font-size: 10px;
      margin: 10px;
    }
  }
`;
