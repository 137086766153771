import React from 'react';

export default function Takeoff({ className, style }: any) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        d="M1.5 13.5H10.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.05793 8.77309L3.41389 10.9818C3.72779 11.2761 4.12822 11.4613 4.55574 11.5099C4.98325 11.5585 5.41505 11.468 5.78702 11.2517L15.25 5.75002L14.085 4.32618C13.7828 3.9568 13.3585 3.70767 12.8886 3.62379C12.4188 3.53991 11.9344 3.6268 11.5231 3.86879L8.74999 5.50002L4.99999 4.25002L3.9688 4.69195C3.89165 4.72502 3.82407 4.77697 3.77228 4.84302C3.72049 4.90908 3.68616 4.9871 3.67246 5.06991C3.65876 5.15272 3.66613 5.23765 3.69388 5.31686C3.72164 5.39607 3.76889 5.46702 3.83128 5.52317L5.74999 7.25002L3.99999 8.25002L2.24999 7.50002L1.20294 7.94875C1.12659 7.98147 1.05959 8.03271 1.008 8.09782C0.956408 8.16294 0.921856 8.23988 0.907464 8.3217C0.893071 8.40351 0.899291 8.48763 0.925562 8.56644C0.951833 8.64525 0.997326 8.71627 1.05793 8.77309V8.77309Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
