import { useCallback } from 'react';
import { useDispatch, useStore } from 'react-redux';

//TODO: use default global startWorking/stopWorking from App
export default function useCallAction(
  action: Function,
  {
    errorMsg = '',
    showError = true,
    startWorking = null,
    stopWorking = null,
  } = {},
) {
  const dispatch = useDispatch();
  const store = useStore();

  const onError = useCallback(error => {
    console.error(error);

    //TODO:

    // const responseErrorMsg =
    //   error.response &&
    //   error.response.data.errors &&
    //   error.response.data.errors.message;
    // const hasResponseErrorMsg = Boolean(responseErrorMsg);

    // responseErrorMsg && console.error(responseErrorMsg);
    // let errorMsgToDisplay = '';
    // if (errorMsg) {
    //   errorMsgToDisplay = errorMsg;
    // } else if (error && error.message && hasResponseErrorMsg) {
    //   errorMsgToDisplay = error.message + ':\n' + responseErrorMsg;
    // } else if (error && error.message) {
    //   errorMsgToDisplay = error.message;
    // } else {
    //   errorMsgToDisplay = error;
    // }

    // sentry.enabled && Sentry.captureException(error);
    // showError && dispatch(setFeedback({ message: errorMsgToDisplay, severity: 'error' }));
  }, []);

  return useCallback(
    async (...args) => {
      //dispatch(startWorking());
      let result;
      try {
        result = await action({ dispatch, store })(...args);
      } catch (error) {
        result = error;
        onError(error);
      } finally {
        //dispatch(stopWorking());
      }

      return result;
    },
    [dispatch, store, action, onError],
  );
}
