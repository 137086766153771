import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { motion } from 'framer-motion';

import { Menu as AntMenu } from 'antd';

import Button from 'app/components/common/Button';
import { Text } from 'app/components/common/Typography';

import useCallAction from 'utils/useCallAction';
import useAction from 'utils/useAction';

import { callDeleteAccount } from 'app/containers/Login/service';
import { actions as loginActions } from 'app/containers/Login/slice';
import { callCreateAccount } from 'app/containers/Admin/service';

import { selectIsAdmin } from 'app/containers/Login/selectors';

export function Menu() {
  const history = useHistory();
  const isAdmin = useSelector(selectIsAdmin);
  const logout = useAction(loginActions.logout);
  const deleteAccount = useCallAction(callDeleteAccount);
  const createAccount = useCallAction(callCreateAccount);

  const onClick = React.useCallback(
    async event => {
      if (event.key === 'profile') {
        history.push('/p/profile');
      } else if (event.key === 'logout') {
        logout();
      } else if (event.key === 'delete') {
        deleteAccount();
      } else if (event.key === 'create') {
        const res = await createAccount();
        history.push(`/${res.handle}`);
      }
    },
    [deleteAccount, history, logout],
  );

  return (
    <AntMenu onClick={onClick}>
      <AntMenu.Item key="profile">Profile</AntMenu.Item>
      <AntMenu.Divider />
      <AntMenu.Item key="logout">Logout</AntMenu.Item>
      {isAdmin && <AntMenu.Item key="create">Create Account</AntMenu.Item>}
      {isAdmin && <AntMenu.Item key="delete">Delete Account</AntMenu.Item>}
    </AntMenu>
  );
}

export function MobileMenu({ isLogged, onClose }) {
  const history = useHistory();
  const logout = useAction(loginActions.logout);
  const deleteAccount = useCallAction(callDeleteAccount);

  const handleLogin = () => {
    onClose();
    history.push('/p/signin');
  };
  const handleGetInTouch = () => {
    onClose();
    history.push('/p/create');
  };
  const handleProfile = () => {
    onClose();
    history.push('/p/profile');
  };
  const handleLogout = () => {
    onClose();
    logout();
  };
  const handleDelete = () => {
    onClose();
    deleteAccount();
  };

  if (isLogged) {
    return (
      <motion.ul variants={variants}>
        <MenuItem onClick={handleProfile}>Profile</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
        <MenuItem onClick={handleDelete}>Delete Account</MenuItem>
      </motion.ul>
    );
  }
  return (
    <motion.ul variants={variants}>
      <MenuItem onClick={handleLogin}>Log in</MenuItem>
      <MenuItem onClick={handleGetInTouch}>Get in touch</MenuItem>
    </motion.ul>
  );
}

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const itemVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

export const MenuItem = ({ onClick, children }) => {
  return (
    <motion.li
      variants={itemVariants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
      <Button variant="text" onClick={onClick} block>
        <Text fontSize="40px" fontWeight="300">
          {children}
        </Text>
      </Button>
    </motion.li>
  );
};
