import React from 'react';
import { useSelector } from 'react-redux';

import { Select } from 'antd';

import Empty from 'app/components/common/Empty';

import useAction from 'utils/useAction';

import { actions } from '../slice';

import { selectFilters } from '../selectors';

import { LANGUAGE_LIST } from 'constants/textConstants';

export default function ClassSizeFilter({ onChange }) {
  const filters = useSelector(selectFilters);
  const [language, setLanguage] = React.useState<string | undefined>();

  const updateFilters = useAction(actions.updateFilters);

  const handleChange = language => {
    setLanguage(language);
    onChange(language);
    updateFilters({
      language,
    });
  };

  const handleClear = () => {
    setLanguage(undefined);
    onChange(undefined);
    updateFilters({
      language: undefined,
    });
  };

  React.useEffect(() => {
    setLanguage(filters?.language);
  }, [filters, setLanguage]);

  return (
    <div>
      <Select
        placeholder="Select language"
        notFoundContent={<Empty />}
        size="large"
        onSelect={handleChange}
        onClear={handleClear}
        value={language}
        style={{ width: '100%' }}
        allowClear
      >
        {LANGUAGE_LIST.map(language => (
          <Select.Option key={language} value={language}>
            {language}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}
