import React from 'react';

export default function Filters({ className, style }: any) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        d="M11.5625 13.437L3.125 13.437"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.875 13.437L14.6875 13.437"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.125 14.9995C13.9879 14.9995 14.6875 14.3 14.6875 13.437C14.6875 12.5741 13.9879 11.8745 13.125 11.8745C12.2621 11.8745 11.5625 12.5741 11.5625 13.437C11.5625 14.3 12.2621 14.9995 13.125 14.9995Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5625 6.56202L3.125 6.56195"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.875 6.56195L9.6875 6.56202"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.125 8.12451C8.98794 8.12451 9.6875 7.42496 9.6875 6.56201C9.6875 5.69907 8.98794 4.99951 8.125 4.99951C7.26206 4.99951 6.5625 5.69907 6.5625 6.56201C6.5625 7.42496 7.26206 8.12451 8.125 8.12451Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
