import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';

import styled from 'styled-components';
import { color } from 'styled-system';

import { media } from 'styles/media';

import { Row, Col } from 'antd';

import {
  Title as BaseTitle,
  Text,
  Paragraph,
} from 'app/components/common/Typography';
import Button from 'app/components/common/Button';

import { APP_NAME } from 'constants/textConstants';

import useAction from 'utils/useAction';

import { actions as systemActions } from '../System/slice';

import { selectIsLogged, selectHandle } from '../Login/selectors';

import HomeBg from './home.webp';
import HomeBgFallback from './home.jpg';
import HomeBgMobile from './home_mobile_min.jpg';
import { ProcessImage, TrainerImage, BookerImage } from './components/Loadable';
// import ProcessImage from './components/ProcessImage';
// import TrainerImage from './components/TrainerImage';
// import BookerImage from './components/BookerImage';

export function HomePage() {
  const history = useHistory();
  const location = useLocation();
  const infoForkRef = React.useRef<any>(null);
  const setColor = useAction(systemActions.setColor);
  const isLogged = useSelector(selectIsLogged);
  const handle = useSelector(selectHandle);

  React.useEffect(() => {
    setColor('primary');
    return () => setColor('default');
  }, [setColor]);

  React.useEffect(() => {
    const hash = location.hash;
    if (hash) {
      // execute next frame
      setTimeout(() => infoForkRef.current?.scrollIntoView(), 1);
    }
  }, [location, infoForkRef]);

  function handleGetStarted() {
    if (isLogged) {
      history.push(`/${handle}`);
    } else {
      history.push(`/p/signin`);
    }
  }

  return (
    <Container>
      <Helmet>
        <title>{APP_NAME}</title>
        <meta name="description" content="Booking trainers made easy." />
      </Helmet>
      <HeaderContainer>
        <Title
          level={1}
          color="textPrimary"
          fontSize={['40px', null, '98px']}
          lineHeight={['48px', null, '94%']}
          maxWidth="880px"
          mb={['64px', null, '104px']}
        >
          Booking trainers made easy.
        </Title>
        <Text
          level={2}
          color="textPrimary"
          fontSize={['20px', null, '36px']}
          lineHeight={['133%', null, '48px']}
          maxWidth="880px"
        >
          Helping you locate and book corporate training contacts
        </Text>
      </HeaderContainer>
      <ImageContainer />
      <Features>
        <Feature bg="rgba(240, 180, 45, 0.1)">
          <TrainerImage />
          <FeatureContent
            title="Find the right trainer for your needs"
            description="Know exactly what you need? Just exploring what might benefit your team? We are your go-to location to explore and discover trainers and training opportunities."
          />
        </Feature>
        <Feature bg="white">
          <ProcessImage />
          <FeatureContent
            title="Enjoy a streamlined booking process"
            description="Purpose-built consolidated end-to-end platform to take the pain out of the process"
          />
        </Feature>
        <Feature bg="rgba(50, 150, 130, 0.1)">
          <BookerImage />
          <FeatureContent
            title="Making your life as a booker easier"
            description="We help you figure out where to start and how to find the services you need"
          />
        </Feature>
      </Features>
      <TrainersContainer>
        <Title level={1}>Some of our featured trainers</Title>
        <Text>Coming soon</Text>
      </TrainersContainer>
      <Row style={{ width: '100%' }} ref={infoForkRef}>
        <Col xs={24} sm={12}>
          <GetInfoBlock $bg="primary">
            <Title level={1} color="textPrimary" fontSize="64px" mb="28px">
              Trainers
            </Title>
            <Button
              variant="bgAccent"
              color="primary"
              size="large"
              onClick={handleGetStarted}
            >
              Get started
            </Button>
          </GetInfoBlock>
        </Col>
        <Col xs={24} sm={12}>
          <GetInfoBlock $bg="secondary">
            <Title level={1} color="textSecondary" fontSize="64px" mb="28px">
              Bookers
            </Title>
            <a href="/p/search">
              <Button variant="bgAccent" color="secondary" size="large">
                Find out more
              </Button>
            </a>
          </GetInfoBlock>
        </Col>
      </Row>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  height: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding: 20px;
  background-color: ${props => props.theme.colors.primary};
  padding: 40px 20px 46px;

  ${media.small`
    padding: 70px 56px 104px;
  `}
`;

const ImageContainer = styled.div`
  @media (max-width: 540px) {
    background: no-repeat center/cover url(${HomeBgMobile});
    height: 400px;
  }
  ${media.small`
    height: 696px;
    .webp & {
      background: no-repeat center/cover url(${HomeBg});
    }
    .no-webp & {
      background: no-repeat center/cover url(${HomeBgFallback});
    }
  `}
`;

const TrainersContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 80px 56px;
`;

const Features = styled.div``;

type FeatureProps = {
  children: any;
  bg?: string;
};
function Feature({ children, bg }: FeatureProps) {
  return (
    <FeatureRoot bg={bg}>
      <div>{children}</div>
    </FeatureRoot>
  );
}

const FeatureRoot = styled.div<any>`
  ${color}

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;

  > div {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    > * {
      max-width: 544px;
      margin: auto;
    }
  }

  &:nth-child(odd) {
    > div {
      flex-direction: row;
    }
  }

  &:nth-child(even) {
    > div {
      flex-direction: row-reverse;
    }
  }

  ${media.small`
    padding: 64px 0px;
  `}
`;

function FeatureContent({ title, description }) {
  return (
    <div>
      <Title level={1} maxWidth="450px" mb="24px">
        {title}
      </Title>
      <Paragraph level={4}>{description}</Paragraph>
    </div>
  );
}

function Title(props) {
  return <BaseTitle fontWeight="300" letterSpacing="-0.02em" {...props} />;
}

const GetInfoBlock = styled.div<any>`
  height: 448px;
  width: 100%;
  background-color: ${props => props.theme.colors[props.$bg] || props.$bg};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
