import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import styled from 'styled-components';
import { variant, layout } from 'styled-system';

import { Layout, Progress, Breadcrumb } from 'antd';

import { themedSize } from 'styles/utils';
import { media } from 'styles/media';

import { Text } from 'app/components/common/Typography';
import Zenu from 'app/components/Zenu';

import { Actions, MobileActions } from './components/Actions';
import Search from './components/Search';

import { selectIsLogged, selectAvatar } from 'app/containers/Login/selectors';
import {
  selectProgress,
  selectSubtitle,
} from 'app/containers/System/selectors';

const { Header } = Layout;

export default function NavBar() {
  const [scroll, setScroll] = React.useState(0);
  const isLogged = useSelector(selectIsLogged);
  const avatar = useSelector(selectAvatar);
  const subtitle2 = useSelector(selectSubtitle);
  const subtitle = useSelector(state => (state as any).system.subtitle);
  const progress2 = useSelector(selectProgress);
  const progress = useSelector(state => (state as any).system.progress);
  const bgColor = useSelector(state => (state as any).system.color);

  const handleScroll = React.useCallback(() => {
    setScroll(Math.min(60, window.scrollY));
  }, [setScroll]);

  React.useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  false &&
    console.log('DEBUG NAV', {
      subtitle,
      subtitle2,
      progress,
      progress2,
      isValie: React.isValidElement(subtitle),
    });

  return (
    <StyledHeader>
      <StyledRoot color={bgColor} height="navBar">
        <ZenuLogo />
        <Actions isLogged={isLogged} avatar={avatar} />
        <MobileActions
          isLogged={isLogged}
          avatar={avatar}
          bgColor={bgColor}
          scroll={scroll}
        />
      </StyledRoot>
      <SecondBar color={bgColor} height="navBar">
        {subtitle && (
          <Subtitle fontSize="14px">
            <BreadcrumbSubtitle nameMap={subtitle} />
          </Subtitle>
        )}
        {!subtitle && (
          <SearchRoot $scroll={scroll}>
            <div>
              <Search />
            </div>
          </SearchRoot>
        )}
      </SecondBar>
      {progress > 0 && (
        <StyledProgress percent={progress} showInfo={false} status="active" />
      )}
    </StyledHeader>
  );
}

const StyledHeader = styled(Header)`
  position: sticky;
  z-index: 1;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: auto;

  top: -${props => props.theme.navBar.size[0]}px;

  ${media.medium`
    top: 0;
  `}
`;

const StyledRoot = styled.div<{ height?: string }>`
  position: relative;
  ${variant({ prop: 'color', key: 'navBarColors' })}
  ${themedSize('height')}
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 0 20px;
  white-space: nowrap;
  ${layout}

  & {
    border-bottom-width: 0;
  }

  ${media.medium`
    padding: 0 56px;
  `}
`;

const ZenuLogo = styled(Zenu)`
  padding: 0;
`;

const StyledProgress = styled(Progress)`
  line-height: 0;

  .ant-progress-inner {
    border-radius: 0;
    background-color: ${props => props.theme.colors.lightGrey};
  }
  .ant-progress-bg {
    height: 4px !important;
    border-radius: 0;
    background-color: ${props => props.theme.colors.primary};
  }
`;

const Subtitle = styled(Text)`
  position: absolute;
  left: 50%;
  top: 50%;
  line-height: 14px;
  transform: translate(-50%, -50%);

  a {
    color: ${props => props.theme.colors.primary};
  }
  a:focus,
  a:hover {
    color: ${props => props.theme.colors.primaryLight};
  }
`;

function BreadcrumbSubtitle({ nameMap }) {
  const location = useLocation();

  const paths = Object.keys(nameMap);
  const currentIndex = paths.findIndex(path => path === location.pathname);
  const items = paths.slice(0, currentIndex + 1).map(url => (
    <Breadcrumb.Item key={url}>
      <StyledLink to={url}>{nameMap[url]}</StyledLink>
    </Breadcrumb.Item>
  ));
  return <Breadcrumb>{items}</Breadcrumb>;
}

const StyledLink = styled(Link)``;

const SecondBar = styled.div<{ height?: string }>`
  ${themedSize('height')}
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  padding: 0 20px;

  > * {
    pointer-events: all;
  }

  position: relative;
  ${variant({ prop: 'color', key: 'navBarColors' })}

  ${media.medium`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: transparent;
  `}
`;

const SearchRoot = styled.div<{ $scroll?: number }>`
  width: 100%;
  display: flex;
  pointer-events: none;

  > div {
    width: calc(100% - ${props => props.$scroll}px);
    max-width: 432px;
    transition: width 0.25s ease-in-out;

    ${media.medium`
      width: 100%;
      max-width: 335px;
    `}

    ${media.large`
      width: 100%;
      max-width: 432px;
    `}

    > * {
      pointer-events: all;
    }
  }

  ${media.small`
    justify-content: center;
  `}
`;
