import makeFetch from 'utils/makeFetch';

export function syncTopics() {
  return async () => {
    const search = makeFetch(`admin/sync/topics`, { method: 'PUT' });
    return search();
  };
}

export function fetchUsers() {
  return async () => {
    const fetch = makeFetch(`admin/users`);
    return fetch();
  };
}

export function fetchIps() {
  return async (ips: string[]) => {
    const sIps = new Set(ips);
    const aIps = Array.from(sIps);
    const fetch = makeFetch(`admin/ips/${aIps.join(',')}`);
    return fetch();
  };
}

type ModificationType = {
  op: 'replace' | 'add' | 'delete';
  path: string;
  value: any;
};
function makeModifyUser(userId: string, modifications: ModificationType[]) {
  return makeFetch(`admin/users/${userId}`, {
    method: 'PATCH',
    body: modifications,
  });
}

export function modifyUserAdmin() {
  return async (handle: string, isAdmin: Boolean) => {
    const modify = makeModifyUser(handle, [
      { op: 'replace', path: 'isAdmin', value: isAdmin },
    ]);
    modify();
  };
}

export function modifyProfileIsHidden() {
  return async (profileId: string, isHidden: Boolean) => {
    const modify = makeFetch(`admin/profile/${profileId}`, {
      method: 'PATCH',
      body: [{ op: 'replace', path: 'isHidden', value: isHidden }],
    });
    modify();
  };
}

export function fetchEmails() {
  return async () => {
    const fetch = makeFetch(`admin/emails`);
    return fetch();
  };
}

export function callCreateAccount() {
  return async () => {
    const fetch = makeFetch(`admin/users`, {
      method: 'POST',
    });
    return fetch();
  };
}
