import React from 'react';

import styled from 'styled-components';

import { Upload, message } from 'antd';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';

import ImgCrop from 'antd-img-crop';

import Modal from 'app/components/Modal';

import makeFetch from 'utils/makeFetch';

export default function UploadModal({
  visible,
  onClose,
  onChange,
  action,
  aspect,
  shape,
}) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState('');

  const handleChange = info => {
    if (info.file.status === 'uploading') {
      setIsLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl => {
        setImageUrl(imageUrl);
        setIsLoading(false);
      });
    }
  };

  const handleRequest = async options => {
    const data = new FormData();
    data.append('image', options.file);
    const upload = makeFetch(options.action, {
      method: 'POST',
      body: data,
    });
    const res = await upload();
    options.onSuccess(res);

    onChange(res.url);
    onClose();
  };

  const uploadButton = (
    <div>
      {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <StyledModal visible={visible} onCancel={onClose} footer={null}>
      <ImgCrop shape={shape} aspect={aspect} rotate grid>
        <Upload
          name="image"
          accept="image/png,image/jpeg"
          listType="picture-card"
          showUploadList={false}
          action={action}
          customRequest={handleRequest}
          beforeUpload={beforeUpload}
          onChange={handleChange}
        >
          {imageUrl ? (
            <img src={imageUrl} alt="preview" style={{ width: '100%' }} />
          ) : (
            uploadButton
          )}
        </Upload>
      </ImgCrop>
    </StyledModal>
  );
}
UploadModal.defaultProps = {
  aspect: 1,
};

const StyledModal = styled(Modal)`
  text-align: center;

  .ant-modal-body {
    padding: 64px 24px;
  }
`;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 < 512;
  if (!isLt2M) {
    message.error('Image must smaller than 512k!');
  }
  return isJpgOrPng && isLt2M;
}
