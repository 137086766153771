import React from 'react';

import styled from 'styled-components';

import { Row, Col, Layout, Form, Input } from 'antd';
import CheckOutlinedIcon from '@ant-design/icons/CheckOutlined';

import { Text, Paragraph } from 'app/components/common/Typography';
import Button from 'app/components/common/Button';
import Divider from 'app/components/common/Divider';

import MailIcon from 'app/components/icons/Mail';

import { media } from 'styles/media';

import useCallAction from 'utils/useCallAction';
import { subscribe } from 'app/containers/Login/service';

export default function Footer() {
  return (
    <Root>
      <StayInTouch />
      <StyledDivider />
      <Legals />
    </Root>
  );
}

const Root = styled(Layout.Footer)`
  padding: 40px 20px 24px;
  background-color: #0c3838;
  color: ${props => props.theme.colors.textPrimary};

  ${media.small`
    padding: 48px 170px;
  `}
`;

const StyledDivider = styled(Divider)`
  opacity: 0.1;
  margin-top: 40px;
  margin-bottom: 24px;

  ${media.small`
    margin-top: 93px;
    margin-bottom: 36px;
  `}
`;

function StayInTouch() {
  const [form] = Form.useForm();
  const [email, setEmail] = React.useState('');
  const [hasError, setHasError] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);

  const callSubscribe = useCallAction(subscribe);

  const handleEmailChange = event => {
    setHasError(false);
    setEmail(event.target.value);
  };

  const handleSignup = async () => {
    try {
      setHasError(false);
      await form.validateFields();
      callSubscribe(email);
      form.resetFields();
      setIsSuccess(true);
    } catch (errorInfo) {
      setHasError(true);
    }
  };

  return (
    <Row gutter={[16, 20]}>
      <Col xs={24} sm={12}>
        <Text>Stay in touch, we'd love to share our journey with you</Text>
        <Paragraph opacity={0.7} mt="12px">
          Find out when we add a new subject. Be informed when a great fit joins
          the network.
          <br />
          No Spam (processed food is so 2020)
        </Paragraph>
      </Col>
      <Col xs={24} sm={12}>
        <StyledForm form={form}>
          <Form.Item
            name="email"
            rules={[{ required: true, type: 'email' }]}
            validateTrigger="onBlur"
            hasFeedback={hasError}
          >
            <StyledInput
              type="email"
              placeholder="Email address"
              prefix={<StyledMailIcon />}
              onChange={handleEmailChange}
            />
          </Form.Item>
          <AnimatedButton
            isSuccess={isSuccess}
            color="primary"
            onClick={handleSignup}
          >
            {isSuccess ? (
              <SuccessIcon />
            ) : (
              <Text mt="20px" mb="20px" ml="22px" mr="22px">
                Sign up
              </Text>
            )}
          </AnimatedButton>
        </StyledForm>
      </Col>
    </Row>
  );
}

const StyledForm = styled(Form)`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 432px;
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 40px;
  padding: 0 0 0 22px;

  .ant-form-item {
    margin-bottom: 0;
    width: 100%;
  }

  .ant-form-item-explain {
    display: none;
  }

  ${media.small`
    margin-left: auto;
  `}
`;

const StyledInput = styled(Input)`
  padding: 0;
  border: none;
  background-color: transparent !important;
  box-shadow: none !important;

  > input {
    color: ${props => props.theme.colors.textPrimary};
    background-color: transparent !important;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      box-shadow: 0 0 0 30px #0c3838 inset !important;
      -webkit-text-fill-color: ${props =>
        props.theme.colors.textPrimary} !important;
    }
  }
`;

const StyledMailIcon = styled(MailIcon)`
  color: rgba(255, 255, 255, 0.6);
`;

function Legals() {
  return (
    <LegalRoot>
      <FooterAnchor to="/termsOfUse.html">Terms of use</FooterAnchor>
      <FooterAnchor to="/privacyPolicy.html">Privacy policy</FooterAnchor>
      <Text opacity={0.7} lineHeight="100%">
        © Zenu inc. {new Date().getFullYear()}
      </Text>
    </LegalRoot>
  );
}

function FooterAnchor({ to, children }) {
  return (
    <a href={to}>
      <Button variant="link" color="textPrimary">
        <Text m={0}>{children}</Text>
      </Button>
    </a>
  );
}

const LegalRoot = styled.div<any>`
  display: flex;
  text-align: center;
  justify-content: space-between;
  > * {
    margin-right: 12px;

    ${media.small`
      margin-right: 42px;
    `}

    &:last-child {
      margin-right: 0;
    }
  }

  ${media.small`
    justify-content: start;
  `}
`;

const SuccessIcon = styled(CheckOutlinedIcon)`
  && {
    margin: 19px;
  }
`;

const AnimatedButton = styled(Button)<{ isSuccess }>`
  margin-left: 22px;
  width: 103px;
  max-width: ${props => (props.isSuccess ? '58px' : '100%')};
  transition: max-width 400ms ease-in-out;

  > span {
    white-space: nowrap;
  }
`;
