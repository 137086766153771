import React from 'react';

export default function Location() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5182 0H1.47629C0.661065 0 0 0.645833 0 1.44167V18.5569C0 19.3528 0.661065 20 1.47629 20H18.5196C19.3348 20 20 19.3528 20 18.5569V1.44167C19.9986 0.645833 19.3348 0 18.5182 0ZM5.93153 17.0431H2.96507V7.49861H5.93153V17.0431ZM4.44969 6.19306C3.49837 6.19306 2.72898 5.42222 2.72898 4.47222C2.72898 3.52361 3.49837 2.75278 4.44969 2.75278C5.39824 2.75278 6.16902 3.52361 6.16902 4.47222C6.16763 5.42222 5.39685 6.19306 4.44969 6.19306ZM17.0419 17.0431H14.0782V12.4014C14.0782 11.2944 14.0574 9.87083 12.5366 9.87083C10.9937 9.87083 10.7562 11.0764 10.7562 12.3208V17.0431H7.79252V7.49861H10.6382V8.80278H10.6784C11.0742 8.05278 12.0422 7.26111 13.4852 7.26111C16.4891 7.26111 17.0433 9.2375 17.0433 11.8083V17.0431H17.0419Z"
        fill="#0077B5"
      />
    </svg>
  );
}
