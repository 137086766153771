import { call, put, takeLatest } from 'redux-saga/effects';
import { searchProfilesApi } from './service';
import { actions } from './slice';

function* searchProfiles(action) {
  try {
    const { subject, topic } = action.payload;
    const { success, profiles } = yield call(searchProfilesApi, subject, topic);
    if (success) {
      const results = profiles.map(profile => {
        const user = profile.user;
        delete profile.user;
        return { ...user, ...profile };
      });

      yield put(actions.setSearchResults(results));
      return profiles;
    }
  } catch (error) {
    console.log(error);
  }
}

function* searchSaga() {
  yield takeLatest(actions.search.type, searchProfiles);
}

export default searchSaga;
