import React from 'react';

export default function ChatDots({ className, style }: any) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        d="M9.35435 12.2427L8.42875 13.7854C8.38432 13.8594 8.32148 13.9207 8.24635 13.9632C8.17121 14.0058 8.08634 14.0281 8 14.0281C7.91366 14.0281 7.82879 14.0058 7.75365 13.9632C7.67851 13.9207 7.61568 13.8594 7.57125 13.7854L6.64565 12.2427C6.60123 12.1687 6.53839 12.1074 6.46325 12.0649C6.38812 12.0223 6.30325 12 6.21691 12H2.5C2.36739 12 2.24021 11.9473 2.14645 11.8535C2.05268 11.7598 2 11.6326 2 11.5V3.5C2 3.36739 2.05268 3.24021 2.14645 3.14645C2.24021 3.05268 2.36739 3 2.5 3H13.5C13.6326 3 13.7598 3.05268 13.8536 3.14645C13.9473 3.24021 14 3.36739 14 3.5V11.5C14 11.5657 13.9871 11.6307 13.9619 11.6913C13.9368 11.752 13.9 11.8071 13.8536 11.8536C13.8071 11.9 13.752 11.9368 13.6913 11.9619C13.6307 11.9871 13.5657 12 13.5 12L9.7831 12C9.69675 12 9.61188 12.0223 9.53675 12.0649C9.46161 12.1074 9.39877 12.1687 9.35435 12.2427Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 8C5.27614 8 5.5 7.77614 5.5 7.5C5.5 7.22386 5.27614 7 5 7C4.72386 7 4.5 7.22386 4.5 7.5C4.5 7.77614 4.72386 8 5 8Z"
        fill="currentColor"
      />
      <path
        d="M8 8C8.27614 8 8.5 7.77614 8.5 7.5C8.5 7.22386 8.27614 7 8 7C7.72386 7 7.5 7.22386 7.5 7.5C7.5 7.77614 7.72386 8 8 8Z"
        fill="currentColor"
      />
      <path
        d="M11 8C11.2761 8 11.5 7.77614 11.5 7.5C11.5 7.22386 11.2761 7 11 7C10.7239 7 10.5 7.22386 10.5 7.5C10.5 7.77614 10.7239 8 11 8Z"
        fill="currentColor"
      />
    </svg>
  );
}
