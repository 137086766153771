import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.login || initialState;

export const selectIsAuthenticating = createSelector(
  [selectDomain],
  loginState => loginState.isAuthenticating,
);

export const selectToken = createSelector(
  [selectDomain],
  loginState => loginState.token,
);

export const selectIsLogged = createSelector([selectToken], token =>
  Boolean(token),
);

export const selectUser = createSelector(
  [selectDomain],
  loginState => loginState.user,
);

export const selectIsAdmin = createSelector([selectUser], user =>
  Boolean(user?.isAdmin),
);

export const selectUserId = createSelector(
  [selectUser],
  user => user && user.id,
);

export const selectAvatar = createSelector(
  [selectUser],
  user => user && user.avatar,
);

export const selectHandle = createSelector(
  [selectUser],
  user => user && user.handle,
);

export const selectWaitingForVerification = createSelector(
  [selectIsLogged, selectUser],
  (isLogged, user) =>
    isLogged &&
    user &&
    user.isVerified.findIndex(({ isVerified }) => !isVerified) !== -1,
);

export const selectError = createSelector(
  [selectDomain],
  loginState => loginState.error,
);
