import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import styled from 'styled-components';

import { Form } from 'antd';

import Page from 'app/components/Page';
import { Title, Text } from 'app/components/common/Typography';
import { FormTextInput } from 'app/components/common/form/TextInput';
import Button from 'app/components/common/Button';

import useCallAction from 'utils/useCallAction';

import { callSetNewPassword } from '../service';

interface ParamTypes {
  token: string;
}

export default function ResetPassword() {
  const history = useHistory();
  const { token } = useParams<ParamTypes>();

  const setPassword = useCallAction(callSetNewPassword);

  const handleFinish = values => {
    setPassword(values.password, token).then(res => {
      if (res.success) {
        history.push('/p/signin');
      }
    });
  };

  return (
    <Page layout>
      <Title level={1} color="primary" mb="32px">
        Reset Password
      </Title>
      <StyledForm
        name="resetPassword"
        onFinish={handleFinish}
        layout="vertical"
      >
        <FormTextInput
          name="password"
          label="Password"
          password
          rules={[{ required: true, message: 'Please enter a password' }]}
        />
        <Button color="primary" block>
          Set as new password
        </Button>
      </StyledForm>
    </Page>
  );
}

const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 24px;
  }
`;
