import React from 'react';
import { useSelector } from 'react-redux';

import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';

import CloseOutlinedIcon from '@ant-design/icons/CloseOutlined';

import Button from 'app/components/common/Button';
import IconButton from 'app/components/common/IconButton';
import { Text } from 'app/components/common/Typography';

import useCallAction from 'utils/useCallAction';

import { callResendVerification } from 'app/containers/Login/service';
import { selectWaitingForVerification } from 'app/containers/Login/selectors';

export default function ValidateEmailBanner() {
  const [isClosed, setIsClosed] = React.useState(false);
  const isWaitingForVerification = useSelector(selectWaitingForVerification);

  const isVisible = isWaitingForVerification && !isClosed;

  const resend = useCallAction(callResendVerification);

  const handleSend = () => {
    resend();
    handleClose();
  };
  const handleClose = () => {
    setIsClosed(true);
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <Root
          initial={{ opacity: 0, y: 200 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 200 }}
        >
          <Text m="12px">
            Your email address has not been verified. You should have received
            an email. Please check your inbox and follow the link.
          </Text>
          <Button
            variant="bgAccent"
            color="primary"
            size="small"
            onClick={handleSend}
            m="12px"
          >
            Resend
          </Button>
          <StyledIconBtn size="medium" color="secondary" onClick={handleClose}>
            <CloseOutlinedIcon />
          </StyledIconBtn>
        </Root>
      )}
    </AnimatePresence>
  );
}

const Root = styled(motion.div)<any>`
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.textSecondary};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const StyledIconBtn = styled(IconButton)`
  position: absolute;
  right: 20px;
`;
