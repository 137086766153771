export const TRAVEL = [
  'Locally (<100km)',
  'Domestically (within your country)',
  'Internationally',
];

export const DURATION = [
  'Short-term assignments (days)',
  'Medium-term assignments (weeks)',
  'Long-term assignments (months)',
];

// prettier-ignore
export const DELIVERY = [
  'On-site',
  'Remote - Live',
  'Remote - Pre-recorded',
];

export const MATERIAL = [
  'No',
  'Yes - Print copies',
  'Yes - Digital copies',
  'Negotiable',
];

// prettier-ignore
export const GROUP_SIZE = [
  '< 10',
  '10 - 30',
  '30 - 50',
  '50 - 100',
  '100+',
];

export const GROUP_SIZE_STR_TO_MIN_MAX = {
  [GROUP_SIZE[0]]: { min: 0, max: 10 },
  [GROUP_SIZE[1]]: { min: 10, max: 30 },
  [GROUP_SIZE[2]]: { min: 30, max: 50 },
  [GROUP_SIZE[3]]: { min: 50, max: 100 },
  [GROUP_SIZE[4]]: { min: 100, max: Number.MAX_SAFE_INTEGER },
};

export const NUMBER_OF_KNOWN_FOR = 4;
