import React from 'react';

import styled from 'styled-components';

import { themedSize } from 'styles/utils';
import { media } from 'styles/media';

import { Title, Paragraph, Text } from 'app/components/common/Typography';
import Button from 'app/components/common/Button';
import IconButton from 'app/components/common/IconButton';
import Mail from 'app/components/Mail';
import MailIcon from 'app/components/icons/Mail';

import { SectionBase } from './Section';

export default function Contact({ profile }) {
  const [showContact, setShowContact] = React.useState(false);

  const handleOpenContact = () => setShowContact(true);
  const handleCloseContact = () => setShowContact(false);

  return (
    <>
      <Root top="navBar">
        <Title level="body" mb="12px">
          {`Email ${profile.firstName}`}
        </Title>
        <Paragraph level="small" mb="12px" lineHeight="133%">
          {`Want to book ${profile.firstName} for a gig? Send them an email to hash out the details!`}
        </Paragraph>
        <Button color="primary" onClick={handleOpenContact} block>
          <Text fontSize="10px">COMPOSE A MESSAGE</Text>
        </Button>
      </Root>
      <MobileRoot>
        <IconButton
          onClick={handleOpenContact}
          size="xlarge"
          color="primary"
          shadow
        >
          <MailIcon />
        </IconButton>
      </MobileRoot>
      <Mail show={showContact} onClose={handleCloseContact} toUser={profile} />
    </>
  );
}

const Root = styled(SectionBase)<{ top: string }>`
  display: none;
  position: fixed;
  ${themedSize('top')}
  left: calc(50% + 370px + 20px);
  transform: translate(0, 32px);

  width: 235px;

  box-shadow: 0px 12px 24px rgba(34, 38, 77, 0.12),
    0px 1px 3px rgba(34, 38, 77, 0.06), 0px 0px 1px rgba(34, 38, 77, 0.25);

  ${media.medium`
    display: block;
  `}
`;

const MobileRoot = styled.div`
  position: fixed;
  bottom: 32px;
  right: 32px;
  z-index: 1;

  && {
    margin-bottom: 0;
  }

  ${media.medium`
    display: none;
  `}
`;
