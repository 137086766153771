import React from 'react';

import styled from 'styled-components';

import { motion, AnimatePresence } from 'framer-motion';

import PlusOutlinedIcon from '@ant-design/icons/PlusOutlined';

import { Title, Paragraph, Text } from 'app/components/common/Typography';

import Button from 'app/components/common/Button';
import Divider from 'app/components/common/Divider';

import Section from './Section';
import EditButton from './EditButton';

export default function Classes({ trainings, isEditMode, onEdit, onNew }) {
  const [selectedTraining, setSelectedTraining] = React.useState<
    number | undefined
  >();

  if (!trainings) return null;
  return (
    <StyledSection noPad overflow>
      <Title level="body">Classes offered</Title>
      {trainings.map((t, i) => (
        <Training
          key={t.id}
          training={t}
          index={i}
          isLast={i === trainings.length - 1}
          isSelected={selectedTraining === i}
          onSelect={setSelectedTraining}
          isEditMode={isEditMode}
          onEdit={onEdit}
        />
      ))}
      <EditButton
        isEditMode={isEditMode}
        onEdit={onNew}
        icon={PlusOutlinedIcon}
      />
    </StyledSection>
  );
}

export function getHasClasses(profile) {
  return Boolean(
    profile?.trainings?.length &&
      profile.trainings.find(({ description }) => Boolean(description)),
  );
}

function Training({
  training,
  index,
  isLast,
  isSelected,
  onSelect,
  isEditMode,
  onEdit,
}) {
  const hastDescription = Boolean(training.description);
  const hasMore = Boolean(
    training.classes?.length || training.qualifications?.length,
  );
  const handleEdit = () => onEdit(index);

  const handleToggle = () =>
    isSelected ? onSelect(undefined) : onSelect(index);

  return (
    <>
      <TrainingRoot isOpened={isSelected}>
        <EditButton isEditMode={isEditMode} onEdit={handleEdit} />
        <div>
          <Rank>{index + 1}</Rank>
          <TrainingInfo>
            <Title level="small" mb="8px">
              {training.trainingTopic?.topic}
              {training.years > 0 && (
                <Text level="small" fontWeight="normal" color="#6a7581">
                  {experienceLabel(training.years)}
                </Text>
              )}
            </Title>
            <Paragraph level="small" lineHeight="133%">
              {hastDescription ? (
                training.description
              ) : isEditMode ? (
                <Text level="note" lineHeight={1}>
                  This class has no description yet.{' '}
                  <span
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={handleEdit}
                  >
                    Want to tell us more?
                  </span>
                </Text>
              ) : null}
            </Paragraph>
            {hasMore && (
              <>
                <AnimatePresence initial={false}>
                  {isSelected && (
                    <motion.div
                      key="content"
                      initial="collapsed"
                      animate="open"
                      exit="collapsed"
                      variants={{
                        open: { opacity: 1, height: 'auto' },
                        collapsed: { opacity: 0, height: 0 },
                      }}
                      transition={{
                        duration: 0.8,
                        ease: [0.04, 0.62, 0.23, 0.98],
                      }}
                    >
                      <ClassesList classes={training.classes} />
                      <Qualifications
                        qualifications={training.qualifications}
                      />
                    </motion.div>
                  )}
                </AnimatePresence>
                <Button variant="text" onClick={handleToggle}>
                  <Text
                    level="note"
                    fontWeight={700}
                    fontSize="10px"
                    m="18px 0 0 0"
                  >
                    {(isSelected && 'SEE LESS') || 'READ MORE'}
                  </Text>
                </Button>
              </>
            )}
          </TrainingInfo>
        </div>
      </TrainingRoot>
      {!isLast && !isSelected && (
        <div style={{ padding: '0 20px' }}>
          <Divider m="-2px 0" />
        </div>
      )}
    </>
  );
}

const StyledSection = styled(Section)`
  padding: 24px 0 0;
  > *:first-child {
    padding-left: 20px;
  }
`;

const TrainingRoot = styled.div<{ isOpened: boolean }>`
  position: relative;
  padding: 24px 20px;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0);
  transition: border-color 0.3s ease-in-out;

  ::after {
    content: '';
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    border-radius: 20px;
    box-shadow: 0px 0px 24px rgba(34, 38, 77, 0.12),
      0px 1px 3px rgba(34, 38, 77, 0.06), 0px 0px 1px rgba(34, 38, 77, 0.25);
    transition: opacity 0.3s ease-in-out;
  }

  ${props =>
    props.isOpened &&
    `
    background-color: white;
    border: 1px solid rgba(228, 230, 232, 1);    

    ::after {
      opacity: 1;
    }
  `}
`;

const Rank = styled.div`
  float: left;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.textPrimary};
  font-size: 18px;
  font-weight: 100;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const TrainingInfo = styled.div`
  margin-left: 52px;
`;

function experienceLabel(years) {
  return years > 1
    ? ` - ${years} years of experience`
    : ` - ${years} year of experience`;
}

function ClassesList({ classes }) {
  if (!classes?.length) return null;
  return (
    <>
      <Title level="small" mt="20px" mb="8px">
        Example classes
      </Title>
      <StyledUl style={{ listStyleType: 'circle' }}>
        {classes.map((c, i) => (
          <li key={i}>
            <Text level="small">{`• ${c}`}</Text>
          </li>
        ))}
      </StyledUl>
    </>
  );
}

const StyledUl = styled.ul`
  padding: 0;
  > li {
    display: inline-block;
    width: 50%;
  }
`;

function Qualifications({ qualifications }) {
  if (!qualifications?.length) return null;
  return (
    <>
      <Title level="small" mt="20px" mb="8px">
        Qualifications
      </Title>
      <QualificationsRoot>
        {qualifications.map((q, i) => (
          <div key={i}>
            <Text level="small" lineHeight="100%">
              {getQualificationTitle(q)}
            </Text>
            <Text level="note" lineHeight="100%" mb="12px">
              {getDetails(q)}
            </Text>
          </div>
        ))}
      </QualificationsRoot>
    </>
  );
}

const QualificationsRoot = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  > div {
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 50%;
  }
`;
function getQualificationTitle(q) {
  return `${q.name}${(q.year && ` (${q.year})`) || ''}`;
}

function getDetails(q) {
  const details: string[] = [];
  q.issuer && details.push(q.issuer);
  return details.join(', ');
}
