import React from 'react';

import styled from 'styled-components';

import PlusOutlinedIcon from '@ant-design/icons/PlusOutlined';

import { Title, Text, Paragraph } from 'app/components/common/Typography';
import Divider from 'app/components/common/Divider';
import BankIcon from 'app/components/icons/Bank';

import Section from './Section';
import EmptyBox from './EmptyBox';
import EditButton from './EditButton';

export default function Education({ diplomas, isEditMode, onEdit, onAdd }) {
  const count = diplomas?.length || 0;

  if (count === 0 && !isEditMode) return null;

  return (
    <Section title="Qualifications">
      <div style={{ paddingTop: '12px' }}>
        {count === 0 ? (
          <EmptyDiploma onAdd={onAdd} />
        ) : (
          diplomas.map((d, i) => (
            <React.Fragment key={d.id}>
              <Diploma diploma={d} onEdit={onEdit} isEditMode={isEditMode} />
              {i < count - 1 && <Divider />}
            </React.Fragment>
          ))
        )}
      </div>
      <EditButton
        isEditMode={isEditMode}
        onEdit={onAdd}
        icon={PlusOutlinedIcon}
      />
    </Section>
  );
}

export function getHasQualifications(profile) {
  return Boolean(profile?.qualifications?.length);
}

function Diploma({ diploma, onEdit, isEditMode }) {
  const handleEdit = () => onEdit(diploma);

  return (
    <DiplomaRoot>
      <EditButton isEditMode={isEditMode} onEdit={handleEdit} right={-6} />
      <BankIcon />
      <div>
        <Title level="small" lineHeight="100%">
          {diploma.issuer}
        </Title>
        <Text level="small">{getDetails(diploma)}</Text>
        {diploma.description && (
          <Paragraph level="small" ml="54px">
            {diploma.description}
          </Paragraph>
        )}
      </div>
    </DiplomaRoot>
  );
}

function EmptyDiploma({ onAdd }) {
  return (
    <DiplomaRoot>
      <BankIcon />
      <EmptyBox onClick={onAdd} ml="52px" mr="28px">
        How smart are those pants actually?{' '}
        <span style={{ textDecoration: 'underline' }}>Add qualification.</span>
      </EmptyBox>
    </DiplomaRoot>
  );
}

const DiplomaRoot = styled.div`
  position: relative;
  clear: left;
  > svg {
    float: left;
    margin-right: 22px;
  }
`;

function getDetails(d) {
  const start = d.startYear ? `${d.startYear}` : '-';
  const end = d.isCurrent ? 'Present' : d.endYear ? `${d.endYear}` : '-';
  const year =
    d.startYear || d.endYear || d.isCurrent ? ` (${start} - ${end})` : '';
  return `${d.name}${year}`;
}
