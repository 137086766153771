import React from 'react';

import styled from 'styled-components';

import { Layout } from 'antd';

import ScrollToTop from './ScrollToTop';

import NavBar from './NavBar';
import Footer from './Footer';

const { Content } = Layout;

interface AppLayoutProps {
  children: any;
  hideFooter?: boolean;
  hideNavBar?: boolean;
}

export default function AppLayout({
  children,
  hideFooter,
  hideNavBar,
}: AppLayoutProps) {
  return (
    <div>
      <RootLayout>
        {!hideNavBar && <NavBar />}
        <ScrollToTop>
          <ContentLayout>
            <Content>{children}</Content>
          </ContentLayout>
        </ScrollToTop>
        {!hideFooter && <Footer />}
      </RootLayout>
    </div>
  );
}

const RootLayout = styled(Layout)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100vh;
  > * {
    flex-grow: 0;
    flex-shrink: 0;
  }
  .ant-layout {
    background-color: ${props => props.theme.colors.background};
  }
`;

const ContentLayout = styled.div`
  width: 100%;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
  /* ::-webkit-scrollbar-track {
    display: none;
  }

  ::-webkit-scrollbar {
      width: auto;
  }

  ::-webkit-scrollbar-thumb {
    background-color: orange;
  } */

  .ant-layout-content {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
