import React from 'react';

export default function Image({ className, style }: any) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        d="M13.5 3H2.5C2.22386 3 2 3.22386 2 3.5V12.5C2 12.7761 2.22386 13 2.5 13H13.5C13.7761 13 14 12.7761 14 12.5V3.5C14 3.22386 13.7761 3 13.5 3Z"
        stroke="#323A3F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 10.5L5.14644 7.3536C5.19287 7.30717 5.24799 7.27034 5.30865 7.24521C5.36931 7.22009 5.43433 7.20715 5.49999 7.20715C5.56565 7.20715 5.63067 7.22009 5.69133 7.24521C5.752 7.27034 5.80711 7.30717 5.85354 7.3536L8.64644 10.1465C8.69287 10.1929 8.74799 10.2298 8.80865 10.2549C8.86931 10.28 8.93433 10.2929 8.99999 10.2929C9.06565 10.2929 9.13067 10.28 9.19133 10.2549C9.252 10.2298 9.30711 10.1929 9.35354 10.1465L10.6464 8.8536C10.6929 8.80717 10.748 8.77034 10.8086 8.74521C10.8693 8.72009 10.9343 8.70715 11 8.70715C11.0657 8.70715 11.1307 8.72009 11.1913 8.74521C11.252 8.77034 11.3071 8.80717 11.3535 8.8536L14 11.5"
        stroke="#323A3F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75 6.75C10.0261 6.75 10.25 6.52614 10.25 6.25C10.25 5.97386 10.0261 5.75 9.75 5.75C9.47386 5.75 9.25 5.97386 9.25 6.25C9.25 6.52614 9.47386 6.75 9.75 6.75Z"
        fill="#2E2E2E"
        stroke="#323A3F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
