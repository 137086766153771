const colors = {
  lightGreen: '#ecf7f4',
  lightGrey: '#e5e5e5',
  lightGreyBg: '#f9f8f7',
  darkGrey: '#72706e',
  charcoal: '#2e2e2e',
  notification: {
    error: '#eb4c80',
  },

  primary: '#329682',
  primaryLight: '#67c7b1',
  primaryDark: '#006756',

  secondary: '#F0B42D',
  secondaryLight: '#ffe661',
  secondaryDark: '#b98500',

  textDefault: '#2e2e2e',
  textPrimary: '#ffffff',
  textSecondary: '#ffffff',

  background: '#ecf7f4',
  foreground: '#ffffff',
};

const breakpoints = ['540px', '860px', '1084px', '1400px'];
// @ts-ignore
breakpoints.sm = breakpoints[0];
// @ts-ignore
breakpoints.md = breakpoints[1];
// @ts-ignore
breakpoints.lg = breakpoints[2];
// @ts-ignore
breakpoints.xl = breakpoints[3];

const lineHeights = [
  '50%',
  '80%',
  '90%',
  '94%',
  '100%',
  '104%',
  '110%',
  '120%',
  '200%',
];

//                  0   1   2   3   4   5   6   7   8
const fontSizes = [12, 14, 16, 18, 20, 24, 28, 36, 48];
// @ts-ignore
fontSizes.h1 = [6, null, null, 7, 8];
// @ts-ignore
fontSizes.h2 = [5, null, null, 6, 7];
// @ts-ignore
fontSizes.h3 = [4, null, null, 5, 6];
// @ts-ignore
fontSizes.h4 = [4, null, null, null, 5];
// @ts-ignore
fontSizes.h5 = [3, null, null, null, 4];
// @ts-ignore
fontSizes.body = [2];
// @ts-ignore
fontSizes.small = [1];
// @ts-ignore
fontSizes.note = [0];

const buttons = {
  contained: {},
  outlined: {},
  link: {
    backgroundColor: 'transparent',
    border: 'none',
    height: 'auto',
    padding: 0,
    '> span': {
      margin: 0,
    },
  },
  text: {
    backgroundColor: 'transparent',
    border: 'none',
  },
};

const buttonTextColors = {
  primary: {
    color: colors.primary,
    ':focus, :hover:enabled': {
      color: colors.textPrimary,
      backgroundColor: colors.primaryLight,
    },
    ':active': {
      color: colors.textPrimary,
      backgroundColor: colors.primaryDark,
    },
    ':disabled': {
      color: colors.darkGrey,
      cursor: 'not-allowed',
    },
  },
  secondary: {
    color: colors.secondary,
    ':focus, :hover:enabled': {
      color: colors.secondaryLight,
      borderColor: colors.secondaryLight,
    },
    ':active': {
      color: colors.secondaryDark,
      borderColor: colors.secondaryDark,
    },
    ':disabled': {
      color: colors.darkGrey,
      cursor: 'not-allowed',
    },
  },
  default: {
    ':focus, :hover:enabled': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
    ':active': {
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
    },
    ':disabled': {
      color: colors.darkGrey,
      cursor: 'not-allowed',
    },
  },
};

const buttonLinkColors = {
  primary: {
    color: colors.primary,
    ':focus, :hover:enabled': {
      color: colors.primaryLight,
    },
    ':active': {
      color: colors.primaryDark,
    },
    ':disabled': {
      color: colors.darkGrey,
      cursor: 'not-allowed',
    },
  },
  secondary: {
    color: colors.secondary,
    ':focus, :hover:enabled': {
      color: colors.secondaryLight,
    },
    ':active': {
      color: colors.secondaryDark,
    },
    ':disabled': {
      color: colors.darkGrey,
      cursor: 'not-allowed',
    },
  },
  textPrimary: {
    color: colors.textPrimary,
    ':focus, :hover:enabled': {
      color: colors.secondaryLight,
    },
    ':active': {
      color: colors.secondaryDark,
    },
    ':disabled': {
      color: colors.darkGrey,
      cursor: 'not-allowed',
    },
  },
};

const buttonOutlinedColors = {
  default: {
    backgroundColor: 'transparent',
    borderColor: colors.lightGrey,
    color: colors.textDefault,
    ':focus, :hover:enabled': {
      color: colors.textDefault,
      backgroundColor: colors.lightGrey,
      borderColor: colors.lightGrey,
    },
    ':active': {
      color: colors.textDefault,
      backgroundColor: colors.lightGreyBg,
      borderColor: colors.lightGreyBg,
    },
    ':disabled': {
      color: colors.darkGrey,
      cursor: 'not-allowed',
    },
  },
  primary: {
    backgroundColor: 'transparent',
    borderColor: colors.primary,
    color: colors.primary,
    ':focus, :hover:enabled': {
      color: colors.primaryLight,
      borderColor: colors.primaryLight,
    },
    ':active': {
      color: colors.primaryDark,
      borderColor: colors.primaryDark,
    },
    ':disabled': {
      color: colors.darkGrey,
      cursor: 'not-allowed',
    },
  },
  secondary: {
    backgroundColor: 'transparent',
    borderColor: colors.secondary,
    color: colors.secondary,
    ':focus, :hover:enabled': {
      color: colors.secondaryLight,
      borderColor: colors.secondaryLight,
    },
    ':active': {
      color: colors.secondaryDark,
      borderColor: colors.secondaryDark,
    },
    ':disabled': {
      color: colors.darkGrey,
      cursor: 'not-allowed',
    },
  },
};

function createButtonContainedColor({
  color,
  lightColor,
  darkColor,
  textColor,
}) {
  return {
    backgroundColor: color,
    borderColor: color,
    color: textColor,
    fontWeight: 'normal',
    ':focus, :hover:enabled': {
      color: textColor,
      backgroundColor: lightColor,
      borderColor: lightColor,
    },
    ':active': {
      color: textColor,
      backgroundColor: darkColor,
      borderColor: darkColor,
    },
    ':disabled': {
      color: colors.darkGrey,
      cursor: 'not-allowed',
    },
  };
}

const buttonContainedColors = {
  default: createButtonContainedColor({
    color: colors.lightGreyBg,
    lightColor: colors.lightGrey,
    darkColor: colors.lightGreyBg,
    textColor: colors.textDefault,
  }),
  primary: createButtonContainedColor({
    color: colors.primary,
    lightColor: colors.primaryLight,
    darkColor: colors.primaryDark,
    textColor: colors.textPrimary,
  }),
  secondary: createButtonContainedColor({
    color: colors.secondary,
    lightColor: colors.secondaryLight,
    darkColor: colors.secondaryDark,
    textColor: colors.textSecondary,
  }),
  primaryLight: createButtonContainedColor({
    color: colors.primaryLight,
    lightColor: colors.primaryLight,
    darkColor: colors.primaryDark,
    textColor: colors.textPrimary,
  }),
  secondaryLight: createButtonContainedColor({
    color: colors.secondaryLight,
    lightColor: colors.secondaryLight,
    darkColor: colors.secondaryDark,
    textColor: colors.textSecondary,
  }),
};

function createButtonBgAccentColor({ textColor }) {
  return {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    border: 'none',
    fontWeight: 'normal',
    color: textColor,
    ':focus, :hover:enabled': {
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
    },
    ':active': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
    ':disabled': {
      color: colors.darkGrey,
      cursor: 'not-allowed',
    },
  };
}

const buttonBgAccentColors = {
  primary: createButtonBgAccentColor({ textColor: colors.textPrimary }),
  secondary: createButtonBgAccentColor({ textColor: colors.textSecondary }),
};

const buttonSizes = {
  small: {
    '> span': {
      fontSize: '14px',
      margin: '11px 16px',
    },
  },
  medium: {
    '> span': {
      margin: '15px 24px',
    },
  },
  large: {
    '> span': {
      margin: '24px 32px',
    },
  },
};

const navBar = {
  size: [60, null, 80, null, null],
  borderColor: colors.lightGrey,
};

const navBarColors = {
  default: {
    backgroundColor: colors.foreground,
    color: colors.primary,
    borderBottom: 'solid 1px',
    borderColor: colors.lightGrey,
  },
  primary: {
    backgroundColor: colors.primary,
    color: colors.textPrimary,
    borderColor: colors.lightGrey,
  },
};

const border = (color = '#CDD1D6') => ({
  borderRadius: 8,
  border: `1px solid ${color}`,
});

////////////////////////////////////////////////////////////////////////////////
// theme

const theme = {
  breakpoints,
  colors,
  fontSizes,
  lineHeights,
  buttons,
  buttonSizes,
  buttonLinkColors,
  buttonTextColors,
  buttonOutlinedColors,
  buttonContainedColors,
  buttonBgAccentColors,
  navBar,
  navBarColors,
  border,
};

export default theme;

export type ThemeType = typeof theme;
