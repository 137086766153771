import React from 'react';
import { useSelector } from 'react-redux';

import { is } from 'ramda';

import { motion } from 'framer-motion';

import styled from 'styled-components';

import { extractCountry } from 'utils/geoLocation';

import { Text } from 'app/components/common/Typography';
import Filters from './components/Filters';
import ResultProfile from './components/ResultProfile';

import { media } from 'styles/media';

import { selectResults, selectFilters, selectCriteria } from './selectors';

import {
  GROUP_SIZE_STR_TO_MIN_MAX,
  TRAVEL,
  DELIVERY,
} from 'app/containers/Profile/constants';

export default function Search() {
  const [filteredResults, setFilteredResults] = React.useState<
    any[] | undefined
  >([]);

  const criteria = useSelector(selectCriteria);
  const results = useSelector(selectResults);
  const filters = useSelector(selectFilters);

  React.useEffect(() => {
    if (!filters) {
      setFilteredResults(results);
      return;
    }
    const filter = filterWith(filters, criteria);
    const filteredResults = results?.filter(filter);
    setFilteredResults(filteredResults);
  }, [filters, criteria, results, setFilteredResults]);

  return (
    <Root>
      <Filters />
      <ResultRoot>
        <ResultLabel filteredResults={filteredResults} />
        {filteredResults && (
          <ResultsContainer variants={variants} initial="hidden" animate="show">
            {filteredResults.map(r => (
              <ResultProfile profile={r} key={r.id} />
            ))}
          </ResultsContainer>
        )}
      </ResultRoot>
    </Root>
  );
}

function filterWith(filters, criteria) {
  const keys = Object.keys(filters);
  return result =>
    !Boolean(
      keys.find(k => {
        const filterFunc = filterFuncs[k];
        if (!filterFunc) throw new Error(`Invalid filter type: ${k}`);
        return !filterFunc(result, filters[k], criteria);
      }),
    );
}

function filterTrainings(trainings, criteria) {
  return trainings.filter(
    t =>
      criteria.subject === t.trainingTopic.subject &&
      (!criteria.topic || criteria.topic === t.trainingTopic.topic),
  );
}

const filterFuncs = {
  price: (profile, filter) => {
    if (!is(Number, profile.priceMin) || !is(Number, profile.priceMax)) {
      return true;
    }
    return profile.priceMin <= filter.max && filter.min <= profile.priceMax;
  },
  classSize: (profile, filter) => {
    if (!profile.groupSize) return true;
    const { min, max } = GROUP_SIZE_STR_TO_MIN_MAX[profile.groupSize];
    return min <= filter.max && filter.min <= max;
  },
  experience: (profile, filter, criteria) => {
    const trainings = filterTrainings(profile.trainings, criteria);
    const maxExp = trainings.reduce(
      (acc, t) => Math.max(acc, t.years || 100),
      0,
    );
    return maxExp >= filter;
  },
  language: (profile, filter) => {
    const profileLanguage =
      profile.language?.length > 0 && Boolean(profile.language[0])
        ? profile.language
        : ['English'];

    return !filter || profileLanguage.indexOf(filter) !== -1;
  },
  location: (profile, filter) => {
    const isOnSite =
      profile.delivery && profile.delivery.indexOf(DELIVERY[0]) !== -1;
    const isRemote =
      profile.delivery &&
      (profile.delivery.indexOf(DELIVERY[1]) !== -1 ||
        profile.delivery.indexOf(DELIVERY[2]) !== -1);
    const isLocal = profile.travel && profile.travel.indexOf(TRAVEL[0]) !== -1;
    const isDomestic =
      profile.travel && profile.travel.indexOf(TRAVEL[1]) !== -1;
    const isInternational =
      profile.travel && profile.travel.indexOf(TRAVEL[2]) !== -1;
    const sameCity = profile.location === filter.city;
    const sameCountry =
      profile.location && extractCountry(profile.location) === filter.country;
    const canReach =
      (isLocal && sameCity) || (isDomestic && sameCountry) || isInternational;

    if (filter.isLocalOnly) return isLocal && sameCity;
    if (filter.isOnSiteOnly && isOnSite && canReach) return false;
    return isRemote || canReach;
  },
};

function ResultLabel({ filteredResults }) {
  if (!filteredResults) return null;
  const count = filteredResults.length;
  const str = count > 1 ? `${count} matches found` : `${count} match found`;
  return (
    <div style={{ width: '100%', paddingLeft: '8px' }}>
      <Text fontWeight="bold" level="note" color="primary">
        {str}
      </Text>
    </div>
  );
}

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;

  ${media.small`
    flex-direction: row;
  `}
`;

const ResultRoot = styled.div`
  padding: 18px 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  //align-items: center;
`;

const ResultsContainer = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
  max-width: 1176px;
  > * {
    margin: 8px;
  }

  @media (min-width: 1176px) {
    justify-content: flex-start;
  }
`;

const variants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.15,
      delayChildren: 0.1,
    },
  },
};
