import React from 'react';
import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import config from '../../config';

import { Form } from 'antd';

import { Title, Paragraph, Text } from 'app/components/common/Typography';
import { FormTextInput } from 'app/components/common/form/TextInput';
import Button from 'app/components/common/Button';
import Divider from 'app/components/common/Divider';

import LinkedIn from './icons/LinkedIn';
import Google from './icons/Google';

import useAction from 'utils/useAction';
import useCallAction from 'utils/useCallAction';

import { actions } from 'app/containers/Login/slice';
import { callResetPassword } from 'app/containers/Login/service';
import { selectError } from 'app/containers/Login/selectors';

function Portal({ target, children }) {
  if (!target) return null;
  return createPortal(children, target);
}

const Terms = styled(Text)`
  && {
    color: ${props => props.theme.colors.darkGrey};
    margin-top: 28px;
    font-size: 12px;
  }
`;

export default function SignIn({ termsEl }) {
  const [mode, setMode] = React.useState('signIn');

  const Component = mode === 'forgot' ? Forgot : SignInComp;

  return (
    <div>
      <Component setMode={setMode} />
      <Portal target={termsEl}>
        <Terms>
          By signing in, you agree to Zenu's{' '}
          <a href="/termsOfUse.html">Terms of Use</a> and{' '}
          <a href="/privacyPolicy.html">Privacy Policy</a>.
        </Terms>
      </Portal>
    </div>
  );
}

function SignInComp({ setMode }) {
  const loginError = useSelector(selectError);
  const login = useAction(actions.loginLocal);
  const [form] = Form.useForm();

  const handleFinish = values => {
    login(values);
  };

  const handleForgot = () => setMode('forgot');

  React.useEffect(() => {
    if (loginError === 'EMAIL_UNKNOWN') {
      form.setFields([
        { name: 'email', errors: ['Unrecognized email address'] },
      ]);
    } else if (loginError === 'EMAIL_OR_PASSWORD_INVALID') {
      form.setFields([
        { name: 'password', errors: ['Incorrect email address or password'] },
      ]);
    }
  }, [loginError, form]);

  return (
    <div>
      <Title level={1} color="primary">
        SIGN IN
      </Title>
      <Paragraph maxWidth="300px" margin="auto" mt="12px">
        Please select a platform to sign in with
      </Paragraph>
      <a href={`${config.SERVER_URL}/api/auth/linkedin`}>
        <Button
          variant="outlined"
          block
          mt="16px"
          mb="12px"
          iconLeft={<LinkedIn />}
        >
          Sign in with LinkedIn
        </Button>
      </a>
      <a href={`${config.SERVER_URL}/api/auth/google`}>
        <Button
          variant="outlined"
          block
          mt="16px"
          mb="12px"
          iconLeft={<Google />}
        >
          Sign in with Google
        </Button>
      </a>
      <Divider mt="24px" mb="24px">
        or
      </Divider>
      <StyledForm
        name="register"
        onFinish={handleFinish}
        layout="vertical"
        form={form}
      >
        <FormTextInput
          name="email"
          label="Email address"
          rules={[
            { required: true, message: 'Please input your email address' },
          ]}
        />
        <FormTextInput
          name="password"
          label="Password"
          password
          rules={[{ required: true, message: 'Please enter a password' }]}
        />
        <Form.Item>
          <a onClick={handleForgot}>Forgot password?</a>
        </Form.Item>
        <Button color="primary" block>
          NEXT
        </Button>
      </StyledForm>
      <Text fontSize="13px" mt="16px">
        Are you a new user? <Link to="/p/create">Sign up</Link>
      </Text>
    </div>
  );
}

function Forgot({ setMode }) {
  const resetPassword = useCallAction(callResetPassword);

  const handleBack = () => setMode('signIn');
  const handleFinish = values => {
    resetPassword(values.email);
    handleBack();
  };

  return (
    <ForgotRoot>
      <Title level={1} color="primary">
        Reset Password
      </Title>
      <Text>Confirm your email and we'll send you the instructions.</Text>
      <StyledForm
        name="register"
        onFinish={handleFinish}
        layout="vertical"
        validateMessages={{
          required: 'Please input your email address',
          types: {
            email: 'The email you entered is not valid',
          },
        }}
      >
        <FormTextInput
          name="email"
          label="Email address"
          rules={[
            {
              required: true,
              type: 'email',
            },
          ]}
          type="email"
        />
        <Button color="primary" block>
          Send Instructions
        </Button>
      </StyledForm>
      <Button onClick={handleBack}>Back to login</Button>
    </ForgotRoot>
  );
}

const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 16px;
  }
`;

const ForgotRoot = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-gap: 24px;
`;
