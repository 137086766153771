import { lazyLoad } from 'utils/loadable';

export const BookerImage = lazyLoad(
  () => import('./BookerImage'),
  module => module.default,
);

export const ProcessImage = lazyLoad(
  () => import('./ProcessImage'),
  module => module.default,
);

export const TrainerImage = lazyLoad(
  () => import('./TrainerImage'),
  module => module.default,
);
