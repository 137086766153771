import React from 'react';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { Slider } from 'antd';

import useAction from 'utils/useAction';

import { actions } from '../slice';

import { selectFilters } from '../selectors';

const MIN = 0;
const MAX = 10;

const MARKS = {
  [MIN]: `${MIN}`,
  [MAX]: `${MAX}+`,
};

export default function ExperienceFilter({ onChange, isOpened }) {
  const filters = useSelector(selectFilters);
  const [min, setMin] = React.useState(0);

  const updateFilters = useAction(actions.updateFilters);
  const resetFilters = useAction(actions.resetFilters);

  const handleChange = min => {
    setMin(min);
    onChange(min, min);
    if (min === MIN) {
      resetFilters(['experience']);
    } else {
      updateFilters({ experience: min });
    }
  };

  React.useEffect(() => {
    setMin(filters?.experience || MIN);
  }, [filters, setMin]);

  return (
    <Root>
      <Slider
        marks={MARKS}
        step={1}
        min={MIN}
        max={MAX}
        value={min}
        tooltipVisible={isOpened}
        tipFormatter={val => (Number(val) > 1 ? `${val}years` : `${val}year`)}
        onChange={handleChange}
      />
    </Root>
  );
}

const Root = styled.div`
  padding-top: 20px;
`;
