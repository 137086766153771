import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import ReactGA from 'react-ga';

import styled from 'styled-components';

import Page from 'app/components/Page';
import SignInComp from 'app/components/SignIn';

import useAction from 'utils/useAction';

import { actions } from './slice';

import { selectProfile } from 'app/containers/Profile/selectors';
import { selectIsAuthenticating, selectIsLogged } from './selectors';

export { default as ResetPassword } from './components/ResetPassword';

export function SignIn() {
  const [termsEl, setTermsEl] = React.useState<null | HTMLDivElement>(null);
  const isAuthenticating = useSelector(selectIsAuthenticating);
  const isLogged = useSelector(selectIsLogged);
  const profile = useSelector(selectProfile);

  const isUserCreationDone = Boolean(profile?.trainings?.length);

  if (isAuthenticating) return null;

  if (isLogged) {
    return !isUserCreationDone ? (
      <Redirect to="/p/create/class" />
    ) : (
      <Redirect to="/p/profile" />
    );
  }
  return (
    <React.Fragment>
      <Page layout>
        <SignInComp termsEl={termsEl} />
      </Page>
      <Space />
      <div ref={setTermsEl} />
    </React.Fragment>
  );
}
interface ParamTypes {
  token: string;
  isSignup: string;
}

export function Logged() {
  const { token, isSignup } = useParams<ParamTypes>();
  const login = useAction(actions.login);

  const isAuthenticating = useSelector(selectIsAuthenticating);
  const profile = useSelector(selectProfile);
  const isUserCreationDone = Boolean(profile?.trainings?.length);

  React.useEffect(() => {
    login(token);
  }, [login, token]);

  React.useEffect(() => {
    ReactGA.event({
      category: 'User',
      action: 'Signup',
      label: 'Trainer',
    });
  }, [isSignup]);

  return isAuthenticating ? null : !isUserCreationDone ? (
    <Redirect to="/p/create/class" />
  ) : (
    <Redirect to="/p/profile" />
  );
}

const Space = styled.div`
  height: 28px;
`;
