import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { QualificationType } from './types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.profile || initialState;

export const selectUi = createSelector(
  [selectDomain],
  profileState => profileState.ui,
);

export const selectIsProcessing = createSelector(
  [selectUi],
  ui => ui.processingRefCount > 0,
);

export const selectError = createSelector(
  [selectDomain],
  profileState => profileState.error,
);

export const selectTrainingTopics = createSelector(
  [selectDomain],
  profileState => profileState.trainingTopics,
);

export const selectValidTrainingTopics = createSelector(
  [selectTrainingTopics],
  topics => topics?.filter(tt => !tt.isDisabled),
);

export const selectProfile = createSelector(
  [selectDomain],
  profileState => profileState.profile,
);

export const selectQualifications = createSelector(
  [selectProfile],
  profile => profile?.qualifications,
);

export const selectTrainings = createSelector(
  [selectProfile, selectValidTrainingTopics, selectQualifications],
  (profile, topics, qualifications) => {
    return (
      profile?.trainings?.map(training => ({
        ...training,
        trainingTopic: topics?.find(
          ({ uid }) => uid === training.trainingTopicUid,
        ),
        qualifications:
          training.qualificationIds?.map(id =>
            qualifications?.find(q => q.id === id),
          ) || [],
      })) || []
    );
  },
);

export const selectDiplomas = createSelector(
  [selectQualifications],
  qualifications => {
    const isSameDiploma = d1 => d2 =>
      d1.id === d2.id || (d1.issuer === d2.issuer && d1.name === d2.name);

    return qualifications?.reduce((acc, q) => {
      q.type === 'Diploma' && !acc.find(isSameDiploma(q)) && acc.push(q);
      return acc;
    }, [] as QualificationType[]);
  },
);
