import React from 'react';

import styled from 'styled-components';

import { Modal, Form, Input } from 'antd';

import { Title, Text } from 'app/components/common/Typography';
import Divider from 'app/components/common/Divider';
import Button from 'app/components/common/Button';

import useCallAction from 'utils/useCallAction';
import { contactUser } from 'app/containers/Profile/service';

const DEFAULT_MESSAGE = `Hi {toName},

I reviewed your Zenu profile and think you’d be a great fit for a session I’d like to organize.

Can we discuss my specific requirements?

Thank you,
{fromName}
`;

type MailProps = {
  fromUser?: {
    firstName: string;
    lastName: string;
    email: string;
  };
  toUser: {
    handle: string;
    firstName: string;
    lastName: string;
  };
  defaultMessage?: string;
  show: boolean;
  onClose: Function;
};

export default function Mail({
  fromUser,
  toUser,
  defaultMessage,
  show,
  onClose,
}: MailProps) {
  const [form] = Form.useForm();

  const [message, setMessage] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [isSending, setIsSending] = React.useState(false);

  const callContactUser = useCallAction(contactUser);

  const defaultValue =
    defaultMessage ||
    DEFAULT_MESSAGE.replace('{toName}', toUser.firstName).replace(
      '{fromName}',
      (fromUser && `${fromUser.firstName} ${fromUser.lastName}`) ||
        '<Your name here>',
    );

  const defaultFromEmail = (fromUser && fromUser.email) || '';

  React.useEffect(() => {
    setMessage(defaultValue);
    setEmail(defaultFromEmail);
  }, [setMessage, defaultValue, setEmail, defaultFromEmail]);

  const handleClose = () => {
    if (isSending) return;
    onClose();
  };
  const handleEmailChange = event => setEmail(event.target.value);
  const handleMessageChange = event => setMessage(event.target.value);
  const handleSend = async () => {
    try {
      await form.validateFields();
      setIsSending(true);
      await callContactUser(email, message, toUser.handle);
      setIsSending(false);
      onClose();
    } catch (errorInfo) {
      console.log(errorInfo);
    }
  };

  return (
    <StyledModal
      visible={show}
      onCancel={handleClose}
      footer={null}
      maskClosable={false}
      $disabled={isSending}
    >
      <Header>{`Message ${toUser.firstName} ${toUser.lastName[0]}.`}</Header>
      <Divider mb="14px" />
      <StyledInputDiv>
        <Text mr="6px" color="darkGrey">
          From:
        </Text>
        <StyledForm
          form={form}
          validateMessages={{
            required: 'Email is required',
            types: {
              email: 'Email is not valid',
            },
          }}
        >
          <Form.Item name="email" rules={[{ required: true, type: 'email' }]}>
            <StyledInput
              type="email"
              placeholder="(Insert your email address)"
              onChange={handleEmailChange}
            />
          </Form.Item>
        </StyledForm>
      </StyledInputDiv>
      <Divider mt="12px" mb="20px" />
      <StyledContent>
        <Text level="note" color="darkGrey">
          Click to edit
        </Text>
        <StyledTextArea
          defaultValue={defaultValue}
          bordered={false}
          onChange={handleMessageChange}
          autoSize
        />
        <Button
          color="primary"
          onClick={handleSend}
          ml="auto"
          loading={isSending}
          disabled={isSending}
        >
          <Text mr="34px" ml="35px" fontWeight="normal">
            Send →
          </Text>
        </Button>
      </StyledContent>
    </StyledModal>
  );
}

const StyledModal = styled(Modal)<any>`
  .ant-modal-content {
    border-radius: 6px;
    ${props => props.$disabled && 'pointer-events: none;'}
  }
  .ant-modal-body {
    padding: 24px 0;
  }
`;

function Header({ children }) {
  return (
    <Title level={5} textAlign="center" color="primary">
      {children}
    </Title>
  );
}

const StyledContent = styled.div`
  margin: 0 28px;
`;

const StyledForm = styled(Form)`
  width: 100%;
  .ant-form-item {
    margin-bottom: 0;
  }
`;

const StyledInputDiv = styled.div`
  margin: 0 28px;
  display: flex;
  > span {
    flex-shrink: 0;
    margin-top: 3px !important;
  }
`;

const StyledInput = styled(Input)`
  padding: 0;
  border: none;
`;

const StyledTextArea = styled(Input.TextArea)`
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  resize: none;
  padding: 0;
  border: none;
`;
