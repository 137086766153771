import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import ReactGA from 'react-ga';

import 'sanitize.css/sanitize.css';
import 'antd/dist/antd.less';
import './styles/main.css';

import config from './config';

// Import root app
import { App } from 'app';

import { HelmetProvider } from 'react-helmet-async';

import { configureAppStore } from 'store/configureStore';

config.IS_PRODUCTION &&
  Sentry.init({
    dsn: config.SENTRY_DNS,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });

ReactGA.initialize(config.GOOGLE_ANALYTICS_ID, {
  debug: !config.IS_PRODUCTION,
});

if (!config.IS_PRODUCTION) {
  ReactGA.set({ sendHitTask: null });
}

const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

interface Props {
  Component: typeof App;
}
const ConnectedApp = ({ Component }: Props) => (
  <Provider store={store}>
    <HelmetProvider>
      <React.StrictMode>
        <BrowserRouter>
          <Component />
        </BrowserRouter>
      </React.StrictMode>
    </HelmetProvider>
  </Provider>
);

const render = (Component: typeof App) => {
  ReactDOM.render(<ConnectedApp Component={Component} />, MOUNT_NODE);
};

if (module.hot) {
  // Hot reloadable translation json files and app
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./app'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    const App = require('./app').App;
    render(App);
  });
}

render(App);

fetch(`${config.SERVER_URL}/version`, {
  headers: { 'Content-type': 'application/json' },
})
  .then(res => res.json())
  .then(({ sha, date, targetEnv }) => {
    console.group('Server config');
    console.log(
      `Environment: ${targetEnv}
Git version: ${sha}
Git date: ${new Date(date)}`,
    );
    console.groupEnd();
    console.log(`
    )        )        
 ( /(     ( /(        
 )\\())(   )\\())   (   
((_)\\ )\\ ((_)\\    )\\  
 _((_|(_) _((_)_ ((_) 
|_  /| __| \\| | | | | 
 / / | _|| .\` | |_| | 
/___||___|_|\\_|\\___/  
`);
  });
