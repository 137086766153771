import styled from 'styled-components';

import { space, SpaceProps } from 'styled-system';

import { motion } from 'framer-motion';

type PageProps = HTMLDivElement | { layout?: boolean } | SpaceProps;

export default styled(motion.div)<PageProps>`
  background-color: ${props => props.theme.colors.foreground};
  border: 1px solid ${props => props.theme.colors.lightGrey};
  width: 100%;
  max-width: 480px;
  border-radius: 6px;
  padding: 56px 40px;
  margin-top: 64px;
  text-align: center;

  transition: height 1.5s ease-in-out;

  ${space}
`;
