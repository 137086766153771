import makeFetch from 'utils/makeFetch';

export const fetchProfileApi = async (handle: string) => {
  const fetchUser = makeFetch(`users/${handle}`);
  return fetchUser();
};

export const updateProfileApi = async (id, data) => {
  const update = makeFetch(`profiles/${id}`, {
    method: 'PUT',
    body: data,
  });
  return update();
};

export const createProfileApi = async data => {
  const create = makeFetch(`profiles`, {
    method: 'POST',
    body: data,
  });
  return create();
};

export async function addProfileTrainingApi(id, data) {
  const add = makeFetch(`profiles/${id}/trainings`, {
    method: 'POST',
    body: data,
  });
  return add();
}

export async function modProfileTrainingApi(profileId, data) {
  const mod = makeFetch(`profiles/${profileId}/trainings/${data.id}`, {
    method: 'PUT',
    body: data,
  });
  return mod();
}

export function deleteTrainingApi(profileId, trainingId) {
  const del = makeFetch(`profiles/${profileId}/trainings/${trainingId}`, {
    method: 'DELETE',
  });
  return del();
}

export function addQualificationApi(id, data) {
  const add = makeFetch(`profiles/${id}/qualifications`, {
    method: 'POST',
    body: data,
  });
  return add();
}

export async function modQualificationApi(id, data) {
  const mod = makeFetch(`profiles/${id}/qualifications/${data.id}`, {
    method: 'PUT',
    body: data,
  });
  return mod();
}

export function deleteQualificationApi(profileId, qualId) {
  const del = makeFetch(`profiles/${profileId}/qualifications/${qualId}`, {
    method: 'DELETE',
  });
  return del();
}

export async function attachQualificationApi(id, trainingId, qualId) {
  const attach = makeFetch(`profiles/${id}/trainings/${trainingId}/${qualId}`, {
    method: 'POST',
  });
  return attach();
}

export async function detachQualificationApi(id, trainingId, qualId) {
  const attach = makeFetch(`profiles/${id}/trainings/${trainingId}/${qualId}`, {
    method: 'DELETE',
  });
  return attach();
}

export function fetchTrainingTopicsApi() {
  const fetchTrainingTopics = makeFetch(`profiles/trainings/topics`);
  return fetchTrainingTopics();
}

export function fetchLocationsApi() {
  return async (location: string) => {
    const search = makeFetch(`profiles/locations/${location}`);
    return search();
  };
}

export function contactUser() {
  return async (fromEmail: string, message: string, toHandle: string) => {
    const send = makeFetch(`users/${toHandle}/contact`, {
      method: 'POST',
      body: { fromEmail, message },
    });
    return send();
  };
}
