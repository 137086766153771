import React from 'react';
import { useSelector } from 'react-redux';
import { Route, RouteProps } from 'react-router-dom';
import { selectIsLogged, selectIsAdmin } from 'app/containers/Login/selectors';

import { SignIn } from 'app/containers/Login';
import { NotFoundPage } from './common/NotFoundPage';

interface PrivateRouteProps extends RouteProps {}

export default function PrivateRoute({
  children,
  path,
  ...rest
}: PrivateRouteProps) {
  const isLogged = useSelector(selectIsLogged);
  const isAdmin = useSelector(selectIsAdmin);

  const component = isAdmin ? (
    children
  ) : isLogged ? (
    <NotFoundPage />
  ) : (
    // <Redirect to={{ pathname: '/p/signin', state: { from: rest.location } }} />
    <SignIn />
  );
  return <Route {...rest}>{component}</Route>;
}
