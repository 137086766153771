import { merge, is } from 'ramda';

import config from '../config';

let currentToken: string | null = null;

export function setToken(token: string | null): void {
  currentToken = token;
}
export function getToken() {
  return currentToken;
}
interface ZenuRequestInitType {
  body?: RequestInit['body'] | object;
  credentials?: RequestInit['credentials'];
  headers?: RequestInit['headers'];
  integrity?: RequestInit['integrity'];
  keepalive?: RequestInit['keepalive'];
  method?: RequestInit['method'];
  mode?: RequestInit['mode'];
  referrer?: RequestInit['referrer'];
  window?: RequestInit['window'];
  signal?: RequestInit['signal'];
}

export default function makeFetch(
  urlPath: string,
  {
    credentials = 'include',
    method = 'GET',
    body,
    headers,
  }: ZenuRequestInitType = {},
) {
  const url = `${config.SERVER_URL}/api/${urlPath}`;

  const isFormData = is(FormData, body);
  const isJson = !isFormData && is(Object, body);
  const finalBody = (isFormData
    ? body
    : isJson
    ? JSON.stringify(body)
    : body) as RequestInit['body'];

  const finalHeaders = merge(
    {
      'x-access-token': currentToken,
    },
    headers,
  );
  isJson && (finalHeaders['Content-type'] = 'application/json');

  return async () => {
    const reponse = await fetch(url, {
      method,
      credentials,
      headers: finalHeaders,
      body: finalBody,
    });
    return reponse.json();
  };
}
