import React from 'react';
import { useSelector } from 'react-redux';

import { Slider } from 'antd';

import useAction from 'utils/useAction';

import { actions } from '../slice';

import { selectFilters } from '../selectors';

const MARKS = {
  10: '10',
  30: '30',
  50: '50',
  75: '100+',
};
const keys = Object.keys(MARKS);
const MIN = Number(keys[0]);
const MAX = Number(keys[keys.length - 1]);

export default function ClassSizeFilter({ onChange }) {
  const filters = useSelector(selectFilters);
  const [minMax, setMinMax] = React.useState<[number, number]>([10, 100]);

  const updateFilters = useAction(actions.updateFilters);
  const resetFilters = useAction(actions.resetFilters);

  const handleChange = minMax => {
    const min = minMax[0];
    const max = minMax[1] === MAX ? 100 : minMax[1];
    setMinMax([min, max]);
    onChange(min, max);
    if (min === MIN && max === 100) {
      resetFilters(['classSize']);
    } else {
      updateFilters({
        classSize: { min, max },
      });
    }
  };

  React.useEffect(() => {
    if (filters?.classSize) {
      setMinMax([filters.classSize.min, filters.classSize.max]);
    } else {
      setMinMax([MIN, MAX]);
    }
  }, [filters, setMinMax]);

  return (
    <div>
      <Slider
        marks={MARKS}
        step={null}
        min={MIN}
        max={MAX}
        value={minMax}
        onChange={handleChange}
        range
      />
    </div>
  );
}
