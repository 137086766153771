import React, { ReactNode, FunctionComponent } from 'react';

import styled from 'styled-components';
import { variant, buttonStyle, space, SpaceProps } from 'styled-system';

import LoadingOutlined from '@ant-design/icons/LoadingOutlined';

import { ThemeType } from 'theme';

type VariantType = 'contained' | 'outlined' | 'link' | 'text';
type SizeType = 'small' | 'medium' | 'large';

type ButtonProps = SpaceProps<ThemeType> & {
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  variant?: VariantType;
  size?: SizeType;
  color?: string;
  block?: boolean;
  loading?: boolean;
};

const Button: FunctionComponent<ButtonProps> = ({
  children,
  iconLeft,
  iconRight,
  loading,
  ...rest
}) => {
  // surround text only children with a <span>
  const decoratedChildren = React.useMemo(() => {
    const arr = React.Children.toArray(children);
    return arr.map((child, i) => {
      if (React.isValidElement(child)) return child;
      return <span key={`c${i}`}>{child}</span>;
    });
  }, [children]);

  return (
    <StyledButton {...rest}>
      {iconLeft && iconLeft}
      {decoratedChildren}
      {iconRight && iconRight}
      {loading && <Loading />}
    </StyledButton>
  );
};

Button.defaultProps = {
  variant: 'contained',
  color: 'default',
  size: 'medium',
  block: false,
  loading: false,
};

export default Button;

const sizeVariant = variant({
  prop: 'size',
  key: 'buttonSizes',
});

const StyledButton = styled.button<ButtonProps>`
  position: relative;
  appearance: none;
  outline: 0;
  cursor: pointer;
  padding: 0;

  font-size: 16px;
  font-weight: normal;

  border-radius: 40px;
  border: 1px solid #e5e5e5;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  > span {
    line-height: 100%;
  }

  > .anticon {
    margin: 0;
  }

  ${props => props.block && 'width: 100%;'}

  ${props => props.shadow && 'box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);'}

  & {
    ${props => getColorVariant(props.variant)}
    ${buttonStyle}
    ${sizeVariant}
  }
  ${space}
`;

function getColorVariant(prop) {
  const key = colorVariantKeys[prop] || 'default';
  return variant({ prop: 'color', key });
}

const colorVariantKeys = {
  contained: 'buttonContainedColors',
  outlined: 'buttonOutlinedColors',
  bgAccent: 'buttonBgAccentColors',
  link: 'buttonLinkColors',
  text: 'buttonTextColors',
};

function Loading() {
  return (
    <StyledLoading>
      <LoadingOutlined />
    </StyledLoading>
  );
}
const StyledLoading = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 18px;
`;
