import React from 'react';

export default function Location() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9998 10.2299C19.9998 9.55015 19.9436 8.86673 19.8236 8.198H10.2012V12.0487H15.7115C15.4829 13.2906 14.7481 14.3892 13.6723 15.0874V17.5859H16.9598C18.8903 15.8443 19.9998 13.2722 19.9998 10.2299Z"
        fill="#4285F4"
      />
      <path
        d="M10.2014 20C12.9528 20 15.2731 19.1145 16.9637 17.586L13.6763 15.0874C12.7616 15.6974 11.5808 16.0428 10.2051 16.0428C7.54368 16.0428 5.28706 14.2828 4.47738 11.9165H1.08496V14.4922C2.81678 17.8689 6.34415 20 10.2014 20Z"
        fill="#34A853"
      />
      <path
        d="M4.47387 11.9165C4.04654 10.6746 4.04654 9.3298 4.47387 8.08788V5.51218H1.0852C-0.361733 8.33773 -0.361733 11.6667 1.0852 14.4922L4.47387 11.9165Z"
        fill="#FBBC04"
      />
      <path
        d="M10.2014 3.95792C11.6558 3.93588 13.0615 4.47233 14.1149 5.45705L17.0275 2.6021C15.1832 0.904558 12.7354 -0.0287207 10.2014 0.000673866C6.34415 0.000673866 2.81678 2.13178 1.08496 5.51216L4.47363 8.08786C5.27957 5.71792 7.53993 3.95792 10.2014 3.95792Z"
        fill="#EA4335"
      />
    </svg>
  );
}
