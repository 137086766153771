import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

import { CookiesProvider } from 'react-cookie';
import { ThemeProvider } from 'styled-components';

import { GlobalStyle } from 'styles/global-styles';
import theme from 'theme';

import { NotFoundPage } from './components/common/NotFoundPage/Loadable';
import PrivateRoute from './components/PrivateRoute';
import AdminRoute from './components/AdminRoute';

import Admin from './containers/Admin/Loadable';
import { Logged, SignIn, ResetPassword } from './containers/Login';
import CreateAccount from './containers/CreateAccount/Loadable';
import { HomePage } from './containers/HomePage';
import Profile from './containers/Profile';
import Search from './containers/Search';

import { APP_NAME } from 'constants/textConstants';

import AppLayout from './components/AppLayout';

import CookieBanner from 'app/components/CookieBanner';
import ValidateEmailBanner from 'app/components/ValidateEmailBanner';

import config from 'config';

import { useInjectReducer } from 'utils/redux-injectors';
import useAction from '../utils/useAction';

import {
  reducer,
  sliceKey,
  actions as loginActions,
} from './containers/Login/slice';

import {
  reducer as profileReducer,
  sliceKey as profileSliceKey,
} from './containers/Profile/slice';

import {
  reducer as systemReducer,
  sliceKey as systemSliceKey,
} from './containers/System/slice';

import {
  reducer as searchReducer,
  sliceKey as searchSliceKey,
} from './containers/Search/slice';

interface ParamTypes {
  token: string;
}

export function App() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectReducer({ key: profileSliceKey, reducer: profileReducer });
  useInjectReducer({ key: systemSliceKey, reducer: systemReducer });
  useInjectReducer({ key: searchSliceKey, reducer: searchReducer });

  const location = useLocation();
  const match = useRouteMatch('/p/logged/:token');
  const token = (match?.params as ParamTypes)?.token;
  const isFullPage = useSelector(state => (state as any).system.isFullPage);

  const login = useAction(loginActions.login);

  React.useEffect(() => {
    const currentPath = location.pathname + location.search;
    ReactGA.set({ page: currentPath });
    ReactGA.pageview(currentPath);
  }, [location]);

  React.useEffect(() => {
    login(token); // using saved token if any
  }, [login, token]);

  const appName = React.useMemo(
    () => (config.TARGET_ENV === 'production' ? APP_NAME : `${APP_NAME}-DEV`),
    [],
  );

  return (
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <Helmet titleTemplate={`%s - ${appName}`} defaultTitle={appName}>
          <meta
            name="description"
            content="Find trainer for your business need"
          />
        </Helmet>
        <AppLayout hideNavBar={isFullPage} hideFooter={isFullPage}>
          <Switch>
            <Route exact path="/">
              <HomePage />
            </Route>
            <AdminRoute path="/p/admin">
              <Admin />
            </AdminRoute>
            <Route exact path="/p/signin">
              <SignIn />
            </Route>
            <Route exact path="/p/logged/:token/:isSignup">
              <Logged />
            </Route>
            <Route exact path="/p/reset/:token">
              <ResetPassword />
            </Route>
            <Route path="/p/create">
              <CreateAccount />
            </Route>
            <PrivateRoute exact path="/p/profile">
              <Profile />
            </PrivateRoute>
            <Route exact path="/p/search">
              <Search />
            </Route>
            <Route exact path="/:handle">
              <Profile />
            </Route>
            <Route component={NotFoundPage} />
          </Switch>
        </AppLayout>
        <CookieBanner />
        <ValidateEmailBanner />
        <GlobalStyle />
      </ThemeProvider>
    </CookiesProvider>
  );
}
